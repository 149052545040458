import React from 'react'
import { HeaderPage } from "../../components/Header/HeaderPage";
import { Footer } from "../../components/Footer/Footer";
import { RecoveryForm } from '../../components/RecoveryForm/RecoveryForm';

const RecoveryPage: React.FC = () => {
  return (
    <>
      <HeaderPage />
      <RecoveryForm />
      <Footer />
    </>
  )
}

export { RecoveryPage }
