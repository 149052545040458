import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {ProductType} from "../types";
import {axiosApi} from "../libs/axios";
import {NavigateFunction} from "react-router-dom";

type InitialStateSearchType = {
    products: {
        pagination: {
            count: number,
            pageCount: number,
        },
        items: ProductType[],
    },
    searchString: string,
    isLoading: boolean;
}

const initialState: InitialStateSearchType = {
    products: {
        pagination: {
            count: 0,
            pageCount: 0,
        },
        items: [],
    },
    searchString: '',
    isLoading: false,
}
export type UseSearchType = {
    searchState: InitialStateSearchType;
    setProducts: (name: string, page: number, sortValue: string, id: string, navigate?: NavigateFunction) => void;
    clearProducts: () => void;
}
export const useSearchSlice = create(immer<UseSearchType>((set) => ({
    searchState: initialState,
    setProducts: async (name: string, page, sortValue: string, id: string, navigate?: NavigateFunction) => {
        try {
            set((state) => {
                state.searchState.isLoading = true
            })
            const data = await axiosApi.get(`/api/products/search-by-name`, {params: {name, page, sortValue, id}});
            if (data.data.status === 'success') {
                set((state) => {
                    state.searchState.products = data.data.data
                    state.searchState.searchString = name
                    navigate && navigate('/search')
                    state.searchState.isLoading = false
                })
            }
        } catch (e) {
            set((state) => {
                state.searchState.isLoading = false
            })
            console.log(e)
        }
    },
    clearProducts: () => {
        set((state) => {
            state.searchState = initialState;
        });
    },

})))

