import React, {useEffect, useRef, useState} from 'react';
import ProductItem from "./ProductItem";
import {ProductType} from "../../../types";
import styles from "./Product.module.css";
import {useLocation, useParams} from "react-router-dom";
import {axiosApi} from "../../../libs/axios";
import {useSearchSlice} from "../../../slices/useSearch";
import {useAuthSlice} from "../../../slices/useAuth";

interface ProductListProps {
    setCount?: React.Dispatch<React.SetStateAction<number>>;
    sortValue: string;
    page:number;
    setPage: React.Dispatch<React.SetStateAction<number>>;

}

const ProductList: React.FC<ProductListProps> = ({setCount, sortValue,page,setPage}) => {
    const params = useParams();
    const id = useAuthSlice(s => s.authState.id)
    const {searchState, setProducts: set} = useSearchSlice()
    const [pageCount, setPageCount] = useState(0);
    const [postPerPage, setPostPerPage] = useState(20);
    const [products, setProducts] = useState<ProductType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [stringWord, setStringWord] = useState('')
    const scroller = useRef<HTMLUListElement>(null);

    function handlePrevious() {
        setPage((p) => {
            if (p === 1) return p;
            return p - 1;
        });
    }

    useEffect(() => {
        if ((scroller && scroller.current)) {
            scroller.current.scrollIntoView({
                behavior: 'smooth'
            })
        }
    }, [page]);


    const path = useLocation().pathname.split('/')[1]
    let tag: string | undefined,
        category: string | undefined,
        subCategory: string | undefined,
        subSubCategory: string | undefined
    if (path === 'categories') {
        tag = params.tag
    } else if (path === 'catalog') {
        category = params.category
        subCategory = params.subCategory
        subSubCategory = params.subSubCategory
    }
    
    let load: boolean
    if (path === 'search') {
        load = searchState.isLoading
    } else {
        load = isLoading
    }
    useEffect(() => {
        if (path === 'search') {
            if (stringWord !== searchState.searchString) setPage(1)
            setPageCount(Math.ceil(+searchState.products.pagination.pageCount))
            setProducts(searchState.products.items)
            setStringWord(searchState.searchString)
        }
    }, [setPage,stringWord, searchState.searchString, path, searchState.products.items, searchState.products.pagination.pageCount]);
    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                if (path === 'search') {
                    set(searchState.searchString, page, sortValue, id )
                } else {
                    let response: any
                    if (tag) {
                        response = await axiosApi.get(`/api/products/get-by-tags`, {
                            params: {
                                tag,
                                page,
                                sortValue,
                                post_per_page: postPerPage,
                                id: id ? id : ''
                            }
                        })
                    } else if (category && !subCategory && !subSubCategory) {
                        response = await axiosApi.get(`/api/products/get-by-categories`, {
                            params: {
                                category,
                                page,
                                sortValue,
                                post_per_page: postPerPage,
                                id: id ? id : ''
                            }
                        })
                    } else if (category && subCategory && !subSubCategory) {
                        response = await axiosApi.get(`/api/products/get-by-sub-categories`, {
                            params: {
                                category,
                                subCategory,
                                page,
                                sortValue,
                                post_per_page: postPerPage,
                                id: id ? id : ''
                            }
                        })
                    } else if (category && subCategory && subSubCategory) {
                        response = await axiosApi.get(`/api/products/get-by-sub-sub-categories`, {
                            params: {
                                category,
                                subCategory,
                                subSubCategory,
                                page,
                                sortValue,
                                post_per_page: postPerPage,
                                id: id ? id : ''
                            }
                        })
                    }
                    if (response.data && response.data.status === 'success') {
                        setProducts(response.data.data.items)
                        setCount && setCount(+response.data.data.pagination.count)
                        setPageCount(Math.ceil(+response.data.data.pagination.pageCount))
                        setIsLoading(false)
                    }
                }
            } catch (e) {
                setIsLoading(false)

                console.log(e)
            }

        })()
    }, [sortValue, category, page, path, searchState.searchString, set, setCount, subCategory, subSubCategory, tag, id, postPerPage])

    function handleNext() {
        setPage((p) => {
            if (p === pageCount) return p;
            return p + 1;
        });
    }


    return (
        load ? <p>Зачекайте...</p> :
            <>
                <ul className={styles.product__list} ref={scroller}>
                    {products.length > 0 ? products.map((item, index) =>
                        <ProductItem key={index} data={item}/>
                    ) : <h1>Товарів не знайдено</h1>}
                </ul>
                {pageCount >= 2 && <div style={{padding: '35px 0'}}>
                    <div style={{display: 'flex', gap: '10px', justifyContent: 'center', marginBottom: '15px'}}>
                        <span style={{fontSize: '18px'}}>Сторінка: <b>{page}</b></span>
                        <span style={{fontSize: '18px'}}>Кількість сторінок: <b>{pageCount}</b></span>
                    </div>
                    <div>
                        <span style={{ fontSize: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            Кількість продуктів на сторінці:
                            <select
                              className='form-select'
                              style={{ maxWidth: 'fit-content', padding: '0.775rem 2rem 0.775rem 1rem', marginLeft: 10 }}
                              value={postPerPage}
                              onChange={(event) => {
                                  setPostPerPage(+event.target.value);
                              }}
                            >
                                <option value='20'>20</option>
                                <option value='40'>40</option>
                                <option value='60'>60</option>
                        </select>
                        </span>
                    </div>
                    <div className={styles.navButtons}
                         style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                        <button style={{ fontSize: '18px', display: 'flex', alignItems: 'center', gap: '5px' }}
                                disabled={page === 1} onClick={handlePrevious}>
                            &laquo; <span>Попередня сторінка</span>
                        </button>

                        <select
                          className='form-select'
                          style={{ maxWidth: 'fit-content', padding: '0.775rem 2rem 0.775rem 1rem' }}
                          value={page}
                          onChange={(event) => {
                              setPage(+event.target.value);
                          }}
                        >
                        {Array(pageCount)
                                .fill(null)
                                .map((_, index) => {
                                    return <option key={index}>{index + 1}</option>;
                                })}
                        </select>

                        <button style={{fontSize: '18px', display: 'flex', alignItems: 'center', gap: '5px'}} disabled={page === pageCount} onClick={handleNext}>
                            <span>Наступна сторінка</span> &raquo;
                        </button>
                    </div>

                </div>}
            </>
    );
};

export default ProductList;