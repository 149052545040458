import React from "react";
import { HeaderPage } from "../../components/Header/HeaderPage";
import { Footer } from "../../components/Footer/Footer";
import BasketSection from "../../components/BasketProductItem/BasketSection";

export const BasketPage = () => {

    return (
      <>
          <HeaderPage />
          <BasketSection />
          <Footer />
      </>
    );
};

