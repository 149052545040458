/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {

    return (
        <>
            {/*<SidebarMenuItem*/}
            {/*    to='/admin/dashboard'*/}
            {/*    icon='element-11'*/}
            {/*    title='Головна панель'*/}
            {/*    fontIcon='bi-app-indicator'*/}
            {/*/>*/}

            {/*<div className='menu-item'>*/}
            {/*    <div className='menu-content pt-8 pb-2'>*/}
            {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Продукти</span>*/}
            {/*    </div>*/}
            {/*</div>*/}


            {/*<SidebarMenuItemWithSub*/}
            {/*    to='/admin/products/'*/}
            {/*    title='Продукти'*/}
            {/*    fontIcon='bi-archive'*/}
            {/*    icon='shop'*/}
            {/*>*/}
            {/*    <SidebarMenuItem to='/admin/products/list' title='Перелік' hasBullet={true}/>*/}
            {/*    <SidebarMenuItem to='/admin/products/setting' title='Налаштування' hasBullet={true}/>*/}
            {/*</SidebarMenuItemWithSub>*/}


            {/*<div className='menu-item'>*/}
            {/*    <div className='menu-content pt-8 pb-2'>*/}
            {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Замовлення</span>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<SidebarMenuItemWithSub*/}
            {/*    to='/admin/orders/'*/}
            {/*    title='Замовлення'*/}
            {/*    fontIcon='bi-archive'*/}
            {/*    icon='purchase'*/}
            {/*>*/}
            {/*    <SidebarMenuItem to='/admin/orders/list' title='Перелік' hasBullet={true}/>*/}
            {/*    <SidebarMenuItem to='/admin/orders/setting' title='Налаштування' hasBullet={true}/>*/}
            {/*</SidebarMenuItemWithSub>*/}

            {/*<div className='menu-item'>*/}
            {/*    <div className='menu-content pt-8 pb-2'>*/}
            {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Користувачі</span>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <SidebarMenuItemWithSub
                to='/admin/users/'
                title='Користувачі'
                fontIcon='bi-archive'
                icon='user'
            >
                <SidebarMenuItem to='/admin/users/create' title='Створити користувача' hasBullet={true}/>
                <SidebarMenuItem to='/admin/users/list' title='Список користувачів' hasBullet={true}/>
                {/*<SidebarMenuItem to='/admin/users/setting' title='Налаштування' hasBullet={true}/>*/}
            </SidebarMenuItemWithSub>
        </>
    )
}

export {SidebarMenuMain}
