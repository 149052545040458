import React, { useState, useEffect, useRef } from 'react';
import styles from './FilterSort.module.css';
import {useAuthSlice} from "../../../slices/useAuth";

type sortOptionsType = {
    news: string;
    desc?: string;
    asc?: string;
};
type FilterSortPropsType ={
    setSortValue:React.Dispatch<React.SetStateAction<string>>;
    sortValue:string;
    setPage: React.Dispatch<React.SetStateAction<number>>;
}
const FilterSort: React.FC<FilterSortPropsType> = ({sortValue,setSortValue,setPage}) => {
    const {authState: {isAuth}} = useAuthSlice()

    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    let sortOptions : sortOptionsType
        isAuth ? sortOptions= {
        news: 'Новинки',
        desc: 'Від дорогих до дешевих',
        asc: 'Від дешевих до дорогих'
    }: sortOptions = {
        news: 'Новинки'
    }

    const handleSortChange = (key: string): void => {
        setSortValue(key);
        setPage(1)
        setDropdownOpen(false);
    };

    const toggleDropdown = (): void => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleClickOutside = (event: MouseEvent): void => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    return (
        <div className={styles.filterSortWrapper} ref={dropdownRef}>
            <span className={styles.title}>Сортування:</span>
            <div className={styles.dropDownContainer}>
                <div onClick={toggleDropdown} className={styles.selectedSort}>
                    {sortOptions[sortValue]}
                </div>

                {dropdownOpen && (
                    <ul className={styles.sortList}>
                        {Object.entries(sortOptions).filter(([key]) => key !== sortValue).map(([key, name]) => (
                            <li
                                key={key}
                                className={key === sortValue ? styles.activeSortOption : ''}
                                onClick={() => handleSortChange(key)}
                            >
                                {name}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default FilterSort;
