import React, {useState} from 'react';
import {Formik, FormikValues} from 'formik';
import axios from "axios";
import {createUserShema} from "../../../helpers/schemas";
import {useNavigate} from "react-router-dom";
import clsx from 'clsx'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from "../../../_metronic/helpers";

export function CreateUserForm() {
    const [show, setShow] = useState(false);
    const navigate = useNavigate()
    const createUser = async (values: FormikValues) => {
        try {
            const response = await axios.post(`/api/users/create`, values)
            if (response.data.data.status === "success") {
                navigate('/admin/users/create')
            } else if (response.data.data.response === "error" && response.data.data.status === 404) {
                alert('Користувач з таким емейлом вже існує')
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Formik
            validationSchema={createUserShema}
            initialValues={{username: '', firstName: '', lastName: '', email: '', password: '', role: '', category: ''}}
            onSubmit={async (values, {setSubmitting}) => {
                setSubmitting(true)
                await createUser(values)
                setSubmitting(false)
            }}
        >
            {({
                  values,
                  isSubmitting,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit
              }) => (
                <form onSubmit={handleSubmit} className='form w-100'>
                    <div className='fv-row mb-4'>
                        <label htmlFor='login'
                               className='form-label fs-6 fw-bolder text-dark'>
                            Логін
                        </label>
                        <input
                            id='login'
                            className={clsx(
                                'form-control bg-transparent',
                                {'is-invalid': touched.username && errors.username},
                                {
                                    'is-valid': touched.username && !errors.username,
                                }
                            )}
                            type="text"
                            name="username"
                            placeholder='Username'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            autoComplete='off'
                        />
                        {touched.username && errors.username && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' style={{color: '#f1416c'}}>{errors.username}</span>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-4'>
                        <label htmlFor='firstName'
                               className='form-label fs-6 fw-bolder text-dark'>
                            Ім'я
                        </label>
                        <input
                            id='firstName'
                            type="text"
                            placeholder='First Name'
                            name="firstName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            autoComplete='off'
                            className={clsx(
                                'form-control bg-transparent',
                                {'is-invalid': touched.firstName && errors.firstName},
                                {
                                    'is-valid': touched.firstName && !errors.firstName,
                                }
                            )}
                        />
                        {touched.firstName && errors.firstName && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' style={{color: '#f1416c'}}>{errors.firstName}</span>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-4'>
                        <label htmlFor='lastName' className='form-label fs-6 fw-bolder text-dark'>Прізвище</label>
                        <input
                            id='lastName'
                            type="text"
                            placeholder='Last Name'
                            name="lastName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            autoComplete='off'
                            className={clsx(
                                'form-control bg-transparent',
                                {'is-invalid': touched.lastName && errors.lastName},
                                {
                                    'is-valid': touched.lastName && !errors.lastName,
                                }
                            )}
                        />
                        {touched.lastName && errors.lastName && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' style={{color: '#f1416c'}}>{errors.lastName}</span>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-4'>
                        <label htmlFor='email' className='form-label fs-6 fw-bolder text-dark'>Email</label>
                        <input
                            id='email'
                            type="email"
                            placeholder='Email'
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            autoComplete='off'
                            className={clsx(
                                'form-control bg-transparent',
                                {'is-invalid': touched.email && errors.email},
                                {
                                    'is-valid': touched.email && !errors.email,
                                }
                            )}
                        />
                        {touched.email && errors.email && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' style={{color: '#f1416c'}}>{errors.email}</span>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-4'>
                        <label htmlFor='password' className='form-label fs-6 fw-bolder text-dark'>Пароль</label>
                        <div style={{position: 'relative'}}>
                            <input
                                id='password'
                                type={`${show ? 'text' : 'password'}`}
                                placeholder='Password'
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                autoComplete='off'
                                style={{position: 'relative'}}
                                className={clsx(
                                    'form-control bg-transparent',
                                    {'is-invalid': touched.password && errors.password},
                                    {
                                        'is-valid': touched.password && !errors.password,
                                    }
                                )}
                            />
                            <span onClick={() => setShow(!show)} className={'password-svg'}>
                            {show ? <SVG src={toAbsoluteUrl('/media/svg/password/eye-open.svg')}/> :
                                <SVG src={toAbsoluteUrl('/media/svg/password/eye-close.svg')}/>}
                        </span>
                        </div>
                        {touched.password && errors.password && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' style={{color: '#f1416c'}}>{errors.password}</span>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-4'>
                        <label htmlFor='lastName' className='form-label fs-6 fw-bolder text-dark'>Категорія</label>
                        <select
                            id='category'
                            name="category"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.category}
                            style={{cursor: 'pointer'}}

                            className={clsx(
                                'form-control bg-transparent',
                                {'is-invalid': touched.category && errors.category},
                                {
                                    'is-valid': touched.category && !errors.category,
                                }
                            )}
                        >
                            <option value=''>Оберіть категорію</option>
                            <option value='f6842ab0-b70b-11eb-bb21-180373b9e379'>Закупка</option>
                            <option value='f6842ab1-b70b-11eb-bb21-180373b9e379'>Опт А</option>
                            <option value='f68e47bd-301a-11ec-bbff-180373b9e379'>Опт Б</option>
                            <option value='f6842ab2-b70b-11eb-bb21-180373b9e379'>Роздріб</option>
                            <option value='3bc871fc-ca46-11ed-be0b-180373b9e379'>А+</option>
                            <option value='c7e079ff-cc45-11eb-bb4a-180373b9e379'>Опт В</option>
                        </select>
                        {touched.category && errors.category && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' style={{color: '#f1416c'}}>{errors.category}</span>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-12'>
                        <label htmlFor='role' className='form-label fs-6 fw-bolder text-dark'>Роль</label>
                        <select
                            id='role'
                            name="role"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.role}
                            style={{cursor: 'pointer'}}

                            className={clsx(
                                'form-control bg-transparent',
                                {'is-invalid': touched.role && errors.role},
                                {
                                    'is-valid': touched.role && !errors.role,
                                }
                            )}
                        >
                            <option value=''>Оберіть роль</option>
                            <option value='customer'>Користувач</option>
                            <option value='admin'>Адміністратор</option>
                        </select>
                        {touched.role && errors.role && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' style={{color: '#f1416c'}}>{errors.role}</span>
                            </div>
                        )}
                    </div>
                    <div className='d-grid mb-10'>
                        <button
                            type='submit'
                            className='btn btn-primary fw-bold px-6'
                            disabled={isSubmitting}
                        >
                            {!isSubmitting ? <span className='indicator-label'>Створити</span>
                                : (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                              Будь ласка зачекайте...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                                )}
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    );
}

