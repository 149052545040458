import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import CategoryList from "./CategoryList";

import styles from "./Category.module.css";
import {axiosApi} from "../../../libs/axios";
import {RequestType} from "../Header/HeaderPage";

export interface CategoryInterface {
    title: string;
    link: string;
    sub?: CategoryInterface[];

}

const Category: React.FC<{setPage:Dispatch<SetStateAction<number>>}> = ({setPage}) => {
    const [categories, setCategories] = useState<RequestType[]>()
    useEffect(() => {
        (async () => {
            try {
                const {data} = await axiosApi.get(`/api/products/get-main-categories`)
                if (data.status === 'success') {
                    setCategories(data.data)
                }
            } catch (e) {
                console.log(e)
            }
        })()
    }, [])
    const mappedCategories = categories?.map(i => ({
        title: i && i.name,
        link: `/catalog/${i.name}`,
        sub: i.subCategories.map(item => (
            {title: item, link: `/catalog/${i.name.replace(' ', '_')}/${item.replace(' ', '_')}`}
        ))
    }))


    return (
        <>
            <div className={styles.categoryWrapper}>
                <span>Категорії:</span>
                <CategoryList   setPage={setPage}   categories={mappedCategories}/>
            </div>
        </>
    );
};

export default Category;