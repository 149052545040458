import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {NavigateFunction} from "react-router-dom";
import {ProductType} from "../types";
import {axiosApi} from "../libs/axios";
import axios from "axios";

let tokenFromStorage: string | null = ''
if (typeof window !== 'undefined') tokenFromStorage = window.localStorage.getItem('wesOptToken');

type InitialStateUseAuthType = {
    api_token: string;
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    username: string;
    category: string,
    role: string;
    isAuth: boolean;
    isLoading: boolean;
    isRecovery: boolean;
    isAuthMe: boolean;
    cart: { id: ProductType, quantity: number, _id: string }[];
}
type UserData = {
    verifyCode: string;
    password: string;
};
const initialState: InitialStateUseAuthType = {
    api_token: tokenFromStorage || '',
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    role: "",
    category: "",
    isAuth: false,
    isLoading: false,
    isRecovery: false,
    isAuthMe: false,
    cart: []
}
export type UseAuthType = {
    sendCodeToEmail: ({email}: { email: string }) => void;
    logOut: () => void;
    authMe: () => void;
    login: (credentials: { password: string, phone: string }) => void;
    authState: InitialStateUseAuthType;
    changePassword: ({verifyCode, password}: UserData, navigate: NavigateFunction) => void;
    addProductToCart: ({id, quantity}: { id: string, quantity: number }) => void;
    removeProductFromCart: ({id: string}) => void;
    clearProducts: () => void;
    changeProductQuantity: ({id, quantity}) => void;
    clearCart: () => void;
}
export const useAuthSlice = create(immer<UseAuthType>((set) => ({
    authState: initialState,
    login: async (credentials: { phone: string, password: string }) => {
        try {
            set((state) => {
                state.authState.isLoading = true
            })
            const data = await axios.post(`api/users/login-customer`, credentials);
            if (data.status === 201) {
                const {api_token, email, firstName, lastName, username, cart, category, role, _id} = data.data.data;
                if (typeof window !== 'undefined') window.localStorage.setItem('wesOptToken', api_token);
                set((state) => {
                    state.authState.api_token = api_token;
                    state.authState.email = email;
                    state.authState.firstName = firstName;
                    state.authState.lastName = lastName;
                    state.authState.username = username;
                    state.authState.category = category;
                    state.authState.cart = cart;
                    state.authState.role = role;
                    state.authState.id = _id;
                    state.authState.isAuth = true;
                    state.authState.isLoading = false
                });
            }
        } catch (e: any) {
            set((state) => {
                state.authState.isLoading = false
            })
            if (e.response.status === 401) {
                alert('Невірно введено логін або пароль!')
            } else {
                console.log(e);
            }
        }
    },
    changePassword: async ({verifyCode, password}: {
        password: string,
        verifyCode: string
    }, navigate: NavigateFunction) => {
        try {
            set((state) => {
                state.authState.isLoading = true
            })
            const data = await axiosApi.post(`api/users/verify-code`, {verifyCode, password});
            if (data.status === 201) {
                set((state) => {
                    state.authState.isLoading = false
                    state.authState.isRecovery = false
                });
                navigate('/login')
            }
        } catch (e: any) {
            set((state) => {
                state.authState.isLoading = false
            })
            if (e.response.data.statusCode === 401) {
                alert(e.response.data.message)
            }
            console.log(e);
        }
    },

    sendCodeToEmail: async ({email}: { email: string }) => {
        try {
            set((state) => {
                state.authState.isLoading = true
            })
            const data = await axiosApi.post(`api/users/recovery_password`, {email});
            if (data.data.status === 'success') {
                set((state) => {
                    state.authState.isLoading = false
                    state.authState.isRecovery = true
                });
            }
        } catch (e: any) {
            set((state) => {
                state.authState.isLoading = false
            })
            if (e.response.data.statusCode === 401) {
                alert(e.response.data.message)
            }
            console.log(e);
        }
    },
    logOut: () => {
        if (typeof window !== 'undefined') window.localStorage.removeItem('wesOptToken');
        set((state) => {
            state.authState = initialState;

        });
    },
    removeProductFromCart: async ({id}) => {
        try {
            set((state) => {
                state.authState.isLoading = true
            })
            let api_token: string | null = null
            if (typeof window !== 'undefined') api_token = window.localStorage.getItem('wesOptToken');
            const data = await axiosApi.post(`/api/users/remove-product-from-cart`, {
                api_token,
                id
            });
            if (data.data.status === 'success') {
                set((state) => {
                    state.authState.isLoading = false
                    state.authState.cart = data.data.data.cart
                })
            }
        } catch (e) {
            set((state) => {
                state.authState.isLoading = false
            })
            console.log(e)
        }
    },
    clearCart: () => {
        set((state) => {
            state.authState.cart = []
        })
    },
    clearProducts: async () => {
        try {
            set((state) => {
                state.authState.isLoading = true
            })
            let api_token: string | null = null
            if (typeof window !== 'undefined') api_token = window.localStorage.getItem('wesOptToken');
            const data = await axiosApi.post(`/api/users/clear-cart`, {
                api_token,
            });
            if (data.data.status === 'success') {
                set((state) => {
                    state.authState.isLoading = false
                    state.authState.cart = data.data.data.cart
                })
            }
        } catch (e) {
            set((state) => {
                state.authState.isLoading = false
            })
            console.log(e)
        }
    },
    addProductToCart: async ({id, quantity}) => {
        try {
            let api_token: string | null = null
            if (typeof window !== 'undefined') api_token = window.localStorage.getItem('wesOptToken');
            set((state) => {
                state.authState.isLoading = true
            })
            const data = await axiosApi.post(`/api/users/add-product-to-cart`, {
                api_token,
                id,
                quantity
            });
            if (data.data.status === 'success') {
                set((state) => {
                    state.authState.isLoading = false
                    state.authState.cart = data.data.data.cart
                })
            }
        } catch (e) {
            set((state) => {
                state.authState.isLoading = false
            })
            console.log(e)
        }
    },
    changeProductQuantity: ({id, quantity}) => {
        set((state) => {
            const index = state.authState.cart.findIndex(i => i.id._id === id)
            state.authState.cart[index].quantity = quantity
        })
    },
    authMe: async () => {
        try {

            let api_token: string | null = null
            if (typeof window !== 'undefined') api_token = window.localStorage.getItem('wesOptToken');
            if (api_token) {
                set((state) => {
                    state.authState.isAuthMe = true
                })
                const data = await axiosApi.post(`/api/users/verify_token`, {api_token})
                if (data.status === 201) {
                    const {email, firstName, lastName, username, role, cart, category, _id} = data.data;
                    set((state) => {
                        state.authState.email = email;
                        state.authState.firstName = firstName;
                        state.authState.lastName = lastName;
                        state.authState.username = username;
                        state.authState.category = category;
                        state.authState.cart = cart;
                        state.authState.role = role;
                        state.authState.id = _id;
                        state.authState.isAuth = true;
                        state.authState.isAuthMe = false
                    });
                } else {
                    set((state) => {
                        state.authState.isAuthMe = false
                    })
                    if (typeof window !== 'undefined') window.localStorage.removeItem('wesOptToken');
                }
            }
        } catch (e) {
            set((state) => {
                state.authState.isLoading = false
            })
            console.log(e)
        }
    },
})))

