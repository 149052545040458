import React, {useEffect, useState} from 'react'
import styles from './SingleProduct.module.css'
import {ProductType} from "../../../types";
import {useParams} from "react-router-dom";
import {useAuthSlice} from "../../../slices/useAuth";
import {axiosApi} from "../../../libs/axios";
import btnCart from '../../../img/header/btnCart_white.svg';
import ModalWindow from "../ModalWindow/ModalWindow";

const SingleProduct: React.FC = () => {
    const [product, setProduct] = useState<ProductType | null>(null)
    const {id} = useParams()
    const { addProductToCart, authState: {category, isAuth, cart}} = useAuthSlice()
    const [quantity, setQuatity] = useState<number | string>(0)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const price = product && product.prices.find(({crmId}) => crmId && crmId === category)?.value
    const existProduct = cart.find(i => i.id._id === product?._id)

    const openModal = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        (async () => {
            try {
                const {data} = await axiosApi.get(`/api/products/get-by-id`, {params: {id}})
                if (data.status === 'success') {
                    const existProduct = cart.find(i => i.id._id === data.data?._id)
                    if (existProduct) {
                        setQuatity(existProduct.quantity)
                    } else {
                        setQuatity(0)
                    }
                    setProduct(data.data)
                    // if (data.data.quantity === 0 && existProduct) {
                    //     removeProductFromCart({id: data.data._id})
                    // }
                    // if (data.data.quantity < cart && cart.find(i => i.id._id === data.data._id)?.quantity) {
                    //     addProductToCart({id: data.data._id, quantity: Number(data.data.quantity)});
                    // }
                }
            } catch (e) {
                console.log(e)
            }
        })()
        // eslint-disable-next-line
    }, [id])

    useEffect(() => {
        if (quantity < 0) {
            setQuatity(0);
        }
    }, [quantity]);


    const isActive = true;

    // const product: Product = {
    //   title: 'Ручка гелева Робот синя 2 шт (1158)',
    //   article: '1158',
    //   section: 'Канцтовари',
    //   category: 'Письмове приладдя',
    //   subCategory: 'Ручки дитячі',
    //   trademark: 'Інше',
    //   type: 'Гелевий',
    //   variation: 'Автоматична',
    //   color: 'Синій',
    //   imageUrl: 'https://panda-lviv.com.ua/upload/catalog/37300/37294/72c3d7d2-67f2-11ee-8d51-902b3450eddc_beba2bd1-67ff-11ee-8d51-902b3450eddc.jpeg',
    //   code: 'Артикул 1158',
    //   tags: [
    //     { value: 'Новинка', color: '#00b8d4' },
    //     { value: 'Знижка', color: '#CC3366' },
    //     { value: 'Надходження', color: '#3faa3d' },
    //   ],
    // };

    return (
        <section className={styles.single__product__section}>
            <div className="container">
                {isModalOpen && (
                    <ModalWindow
                        onClose={() => setIsModalOpen(false)}
                        image={`${process.env.REACT_APP_SERVER_URL}/${product?.image ? product?.image : 'media/products/woocommerce-placeholder.png'}`}
                    />
                )}

                <div className={styles.link__block}>
                    <a href='/' className={styles.inactiveLink}>
                        Головна
                    </a>
                    <b>/</b>
                    {product?.categories?.name && <><a href={`/catalog/${product?.categories?.name?.replace(' ', '_')}`}
                                                       className={styles.inactiveLink}>
                        {product?.categories?.name}
                    </a>
                        <b>/</b></>}
                    {product?.categories?.subCategory?.name && <><a
                        href={`/catalog/${product?.categories?.name?.replace(' ', '_')}/${product?.categories?.subCategory?.name.replace(' ', '_')}`}
                        className={styles.inactiveLink}>
                        {product?.categories?.subCategory?.name}
                    </a>
                        <b>/</b></>}
                    {product?.categories?.subCategory?.subCategory?.name &&
                        <>< a
                            href={`/catalog/${product?.categories?.name?.replace(' ', '_')}/${product?.categories?.subCategory?.name?.replace(' ', '_')}/${product?.categories?.subCategory?.subCategory?.name.replace(' ', '_')}`}
                            className={styles.inactiveLink}>
                            {product?.categories?.subCategory?.subCategory?.name}
                        </a>
                            <b>/</b></>}
                    <p
                        // href={`/catalog/product/${product?._id}`}
                        className={isActive ? styles.activeLink : styles.inactiveLink}>
                        {product?.name}
                    </p>
                </div>

                <div className={styles.product__block}>
                    <div className={styles.product__visual}>
                        <div className={styles.product__visual__wrapper}>

                            <div>
                                <div onClick={openModal} style={{cursor: 'pointer'}}>
                                    <div className={styles.tags__wrapper}>
                                        {product && product.isDiscountTag && <div className={styles.tag}
                                                                                  style={{background: '#CC3366'}}>
                                            <p>Знижка</p>
                                        </div>
                                        }
                                        {product && product.isReceiptsTag && <div className={styles.tag}
                                                                                  style={{background: `#3faa3d`}}>
                                            <p>Надходження</p>
                                        </div>}
                                        {product && product.isNewTag && <div className={styles.tag}
                                                                             style={{background: `#4b7a3d`}}>
                                            <p>Новинка</p>
                                        </div>}
                                    </div>
                                    <img
                                        src={`${process.env.REACT_APP_SERVER_URL}/${product?.image ? product?.image : 'media/products/woocommerce-placeholder.png'}`}
                                        alt={product?.name}/>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={styles.product__info}>
                        <div className={styles.product__info__first}>
                            <h3 className={styles.product__info__title}>{product?.name}</h3>
                            <p className={styles.product__info__article}>Артикул {product?.article}</p>
                        </div>

                        {product?.description ?
                          <div className={styles.product__info__description}>
                              <h3 className={styles.product__info__ds__title}>Опис товару</h3>
                              <p className={styles.product__info__ds__content}>{product?.description}</p>
                          </div> : ''
                        }

                        <div className={styles.product__info__second}>
                            {isAuth &&
                              <div className={styles.product__info__row}>
                                  <p className={styles.product__info__text}>Ціна</p>
                                  <p className={styles.product__info__text}>{price} грн.</p>
                              </div>}
                            {/*{isAuth &&*/}
                            {/*    <div className={styles.product__info__row}>*/}
                            {/*        <p className={styles.product__info__text}>Кількість</p>*/}
                            {/*        <p className={styles.product__info__text}>{product?.quantity}</p>*/}
                            {/*    </div>}*/}

                            {product?.characteristics.map(({name, value}) =>
                              (<div className={styles.product__info__row}>
                                  <p className={styles.product__info__text}>{name}</p>
                                  <p className={styles.product__info__text}>{value}</p>
                              </div>)
                            )}


                            {/*<div className={styles.product__info__row}>*/}
                            {/*    <p className={styles.product__info__text}>Вид</p>*/}
                            {/*    <p className={styles.product__info__text}>{product.variation}</p>*/}
                            {/*</div>*/}

                            {/*<div className={styles.product__info__row}>*/}
                            {/*    <p className={styles.product__info__text}>Колір</p>*/}
                            {/*    <p className={styles.product__info__text}>{product.color}</p>*/}
                            {/*</div>*/}
                            {isAuth && <div className={styles.counter__wrapper}>
                                <div className={styles.cart__total}>
                                    <button
                                        className='btn__minus'
                                        disabled={quantity === 0}
                                        onClick={() => setQuatity(p => p === 0 ? p : +p - 1)}>-
                                    </button>
                                    <input
                                        disabled={product?.quantity === 0}
                                        min={-1}
                                        // max={product?.quantity}
                                        onFocus={() => setQuatity("")}
                                        onChange={(e) => setQuatity(+e.target.value)}
                                        value={quantity || 0}
                                        type="number"
                                    />
                                    <button
                                        className='btn__plus'
                                        disabled={product?.quantity === 0}
                                        onClick={() => setQuatity(p => +p + 1)}>
                                        +
                                    </button>
                                </div>
                                <div className={styles.order__wrapper}>
                                    <button
                                        className={styles.order__btn}
                                        disabled={product?.quantity === 0}
                                        onClick={() => {
                                            if (product?._id) {
                                                quantity && quantity > 0 && addProductToCart({
                                                    id: product._id,
                                                    quantity: +quantity
                                                });
                                            } else {
                                                console.error("Product ID is undefined");
                                            }
                                        }}>
                                        <img className={styles.cart__icon} src={btnCart} alt="cart"/>
                                        {existProduct ? `В кошику ${existProduct.quantity} шт.` : 'Купити'}
                                    </button>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export {SingleProduct}
