import React from 'react'
import { HeaderPage } from "../../components/Header/HeaderPage";
import { Footer } from "../../components/Footer/Footer";
import { LoginForm } from '../../components/LoginForm/LoginForm';

const LoginPage: React.FC = () => {
  return (
    <>
      <HeaderPage />
      <LoginForm />
      <Footer />
    </>
  )
}

export { LoginPage }
