/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {HeaderCatalog} from "./HeaderCatalog";
import logo from '../../../img/header/default-dark.svg';
import btnCart from '../../../img/header/btnCart.svg';
import info from '../../../img/header/info.svg';
import {ReactComponent as Search} from "../../../img/header/btn-search__white_1.svg";
import styles from './HeaderPage.module.css';
import HeaderMobile from "./HeaderMobile";
import {Link, useNavigate} from "react-router-dom";
import {useAuthSlice} from "../../../slices/useAuth";
import {axiosApi} from "../../../libs/axios";
import {useSearchSlice} from "../../../slices/useSearch";

export  type RequestType = {
    name: string;
    subCategories: string[]
}
// type TagsType = {
//     isReceiptsTag: boolean;
//     isDiscountTag: boolean;
//     isNewTag: boolean;
// }

const HeaderPage: React.FC = () => {
    const [categories, setCategories] = useState<RequestType[]>()
    const [open, setOpen] = useState<boolean>(false)
    const id = useAuthSlice(s => s.authState.id)
    // const [tags, setTags] = useState<TagsType>({
    //     isReceiptsTag: false,
    //     isDiscountTag: false,
    //     isNewTag: false,
    // })
    const [searchString, setSearchString] = useState<string>('')
    const {setProducts} = useSearchSlice()
    const {authState: {isAuth, lastName, firstName, cart}, logOut} = useAuthSlice()
    const navigate = useNavigate()
    const search = () => {
        if (searchString) setProducts(searchString, 1,'news',id, navigate)
    }
    useEffect(() => {
        (async () => {
            try {
                const {data} = await axiosApi.get(`/api/products/get-main-categories`)
                if (data.status === 'success') {
                    setCategories(data.data)
                }
            } catch (e) {
                console.log(e)
            }
        })()
    }, [])

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const {data} = await axiosApi.get(`/api/products/get-tags`)
    //             if (data.status === 'success') {
    //                 setTags(data.data)
    //             }
    //         } catch (e) {
    //             console.log(e)
    //         }
    //     })()
    // }, [])
    return (
        <>
            <div className={styles.header}>
                <div className="container">
                    <div className={styles.header__wrapper}>
                        <nav className={styles.header__nav}>
                            <Link id="logo" to="/">
                                <img src={logo} alt="logo"/>
                            </Link>
                            <ul className={styles.nav__link__list}>
                                <li>м. Луцьк вул. Ветеранів 1А  e-mail: office.1v@ukr.net</li>
                                {/*{tags.isNewTag && <li>*/}
                                {/*    <Link className={styles.nav__link} to="/categories/news">*/}
                                {/*        Новинки*/}
                                {/*    </Link>*/}
                                {/*</li>}*/}
                                {/*{tags.isDiscountTag && <li>*/}
                                {/*    <Link className={styles.nav__link} to="/categories/discount">*/}
                                {/*        Акції*/}
                                {/*    </Link>*/}
                                {/*</li>}*/}
                                {/*{tags.isReceiptsTag && <li>*/}
                                {/*    <Link className={styles.nav__link} to="/categories/arrival">*/}
                                {/*        Надходження*/}
                                {/*    </Link>*/}
                                {/*</li>}*/}

                            </ul>
                            <ul className={styles.nav__list__contacts}>
                                <li>
                                    <a className={styles.nav__contact} href="tel:+380992293270">
                                        +38 (099) 229-32-70
                                        <span>Канцтовари</span>
                                    </a>
                                </li>
                                <li>
                                    <a className={styles.nav__contact} href="tel:+380992293270">
                                        +38 (099) 229-32-70
                                        <span>Іграшки</span>
                                    </a>
                                </li>
                                <li className={styles.header__list__info}>
                                    <img className="logo" src={info} alt="info"/>
                                </li>
                                <li className={styles.header__info__tooltip}>
                                    <p className={styles.header__info__tooltip__title}>
                                        Графік роботи
                                    </p>
                                    <p>Пн - Чт</p>
                                    <p>9:00 - 18:00</p>
                                </li>
                            </ul>
                        </nav>
                        <div className={styles.header__down}>
                            <div className={styles.header__catalog}>
                                <HeaderCatalog categories={categories}/>
                            </div>
                            <div className={styles.header__search__wrapper}>
                                <label className={styles.header__search__label} htmlFor="search">
                                    <input id="search" type="text" placeholder={'Пошук...'}
                                           value={searchString}
                                           onChange={(e) => {
                                               setSearchString(e.target.value)
                                           }}
                                    />
                                </label>
                                <button onClick={search}>
                                    <Search/>
                                </button>
                            </div>
                            {!isAuth ? <ul className={styles.btn__list__down}>
                                    <li className={`${styles.nav__link} ${styles.nav__link_login}`}>
                                        <Link to="/login">
                                            Увійти
                                        </Link>
                                    </li>
                                </ul>
                                :
                                <ul className={styles.btn__list__down}>

                                    <li>
                                        <div className={styles.user__wrapper}
                                             onClick={() => setOpen(p => !p)}>
                                            {/*<img src={user} alt="user"/>*/}
                                            <p>{`${firstName} ${lastName}`}</p>
                                            {open && <div className={styles.user__btn}>
                                                <button className={styles.order__btn}
                                                        onClick={() => navigate('/profile/orders')}>Замовлення
                                                </button>
                                                <button className={styles.order__btn__out} onClick={() => {
                                                    logOut();
                                                    navigate('/login')
                                                }}>Вийти
                                                </button>
                                            </div>}
                                        </div>
                                    </li>
                                    <li>
                                        <button disabled={!cart.length} className={styles.btn__list__down__link}
                                                onClick={() => navigate("/profile/basket")}>
                                            {cart.length > 0 && <div className={styles.cart__item}>{cart.length}</div>}
                                            <img className={styles.cart__icon} src={btnCart} alt="cart"/>
                                            Кошик
                                        </button>
                                    </li>
                                </ul>

                            }
                        </div>
                    </div>
                </div>
            </div>
            <HeaderMobile categories={categories} isAuth={isAuth}/>
        </>
    )
}

export {HeaderPage}
