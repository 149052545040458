import React, {useState} from 'react';

import styles from '../LoginForm/LoginForm.module.css'
import {Formik} from "formik";
import {emailShema, verifySchema} from "../../../helpers/schemas";
import clsx from "clsx";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import {useAuthSlice} from "../../../slices/useAuth";
import {useNavigate} from "react-router-dom";

type RecoveryValues = {
    verifyCode: string;
    password: string;
}

type EmailValues = {
    email: string;
}

const RecoveryForm: React.FC = () => {
    const isActive = true;
    const [show, setShow] = useState(false);
    const {changePassword, sendCodeToEmail, authState: {isLoading, isRecovery}} = useAuthSlice()
    const navigate = useNavigate()
    return (
        <section className={styles.form__section}>
            <div className="container">
                <div className={styles.link__block}>
                    <a href='/' className={isActive ? styles.inactiveLink : styles.activeLink}>
                        Головна
                    </a>
                    <b>/</b>
                    <a href='/login' className={isActive ? styles.activeLink : styles.inactiveLink}>
                        Відновлення паролю
                    </a>
                </div>
                <div className={styles.recovery__block}>
                    <div className={styles.recovery__block__form}>
                        <h3 className={styles.recovery__block__title}>
                            Відновлення паролю
                        </h3>
                        <div className={styles.recovery__form}>
                            <Formik
                                validationSchema={isRecovery ? verifySchema : emailShema}
                                initialValues={isRecovery ? {verifyCode: '', password: ''} : {
                                    email: '',
                                    verifyCode: '',
                                    password: ''
                                }}
                                onSubmit={async (values) => {
                                    if (isRecovery) {
                                        changePassword(values as RecoveryValues, navigate)
                                    } else if (!isRecovery) {
                                        sendCodeToEmail(values as EmailValues)
                                    }
                                }}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit
                                  }) => (
                                    <form onSubmit={handleSubmit} className='form w-100'>
                                        {!isRecovery ? <div className='fv-row mb-4'>
                                                <label htmlFor='email'
                                                       className='form-label fs-6 fw-bolder text-dark'>Email</label>
                                                <input
                                                    id='email'
                                                    type="email"
                                                    placeholder='Email'
                                                    name="email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    autoComplete='off'
                                                    className={clsx(
                                                        'form-control bg-transparent',
                                                        {'is-invalid': touched.email && errors.email},
                                                        {
                                                            'is-valid': touched.email && !errors.email,
                                                        }
                                                    )}
                                                />
                                                {touched.email && errors.email && (
                                                    <div className='fv-plugins-message-container'>
                                                        <span role='alert' style={{color: '#f1416c'}}>{errors.email}</span>
                                                    </div>
                                                )}
                                            </div>

                                            : <>
                                                <div className='fv-row mb-4'>
                                                    <label htmlFor='verifyCode'
                                                           className='form-label fs-6 fw-bolder text-dark'>Код</label>
                                                    <div style={{position: 'relative'}}>
                                                        <input
                                                            id='verifyCode'
                                                            type='text'
                                                            placeholder='Введіть код'
                                                            name="verifyCode"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.verifyCode}
                                                            autoComplete='off'
                                                            style={{position: 'relative'}}
                                                            className={clsx(
                                                                'form-control bg-transparent',
                                                                {'is-invalid': touched.verifyCode && errors.verifyCode},
                                                                {
                                                                    'is-valid': touched.verifyCode && !errors.verifyCode,
                                                                }
                                                            )}
                                                        />
                                                    </div>
                                                    {touched.verifyCode && errors.verifyCode && (
                                                        <div className='fv-plugins-message-container'>
                                                    <span role='alert'
                                                          style={{color: '#f1416c'}}>{errors.verifyCode}</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='fv-row mb-4'>
                                                    <label htmlFor='password'
                                                           className='form-label fs-6 fw-bolder text-dark'>Пароль</label>
                                                    <div style={{position: 'relative'}}>
                                                        <input
                                                            id='password'
                                                            type={`${show ? 'text' : 'password'}`}
                                                            placeholder='Password'
                                                            name="password"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.password}
                                                            autoComplete='off'
                                                            style={{position: 'relative'}}
                                                            className={clsx(
                                                                'form-control bg-transparent',
                                                                {'is-invalid': touched.password && errors.password},
                                                                {
                                                                    'is-valid': touched.password && !errors.password,
                                                                }
                                                            )}
                                                        />
                                                        <span onClick={() => setShow(!show)} className={'password-svg'}>
                                                            {show ? <SVG
                                                                    src={toAbsoluteUrl('/media/svg/password/eye-open.svg')}/> :
                                                                <SVG
                                                                    src={toAbsoluteUrl('/media/svg/password/eye-close.svg')}/>}
                                                        </span>
                                                    </div>
                                                    {touched.password && errors.password && (
                                                        <div className='fv-plugins-message-container'>
                                                    <span role='alert'
                                                          style={{color: '#f1416c'}}>{errors.password}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </>}

                                        <div className='d-grid mb-10'>
                                            <button
                                                type='submit'
                                                className='btn btn-primary fw-bold px-6'
                                                disabled={isLoading}
                                            >
                                                {!isLoading ? <span
                                                        className='indicator-label'>{!isRecovery ? 'Надіслати код' : 'Змінити пароль'}</span>
                                                    : (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                              Будь ласка зачекайте...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                                                    )}
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <div className={styles.recovery__block__registration}>
                        <div className={styles.recovery__block__info}>
                            <p>
                                Вже зареєстровані?
                            </p>
                            <a href='/login'>
                                Увійти
                            </a>
                        </div>
                        <div className={styles.recovery__block__info}>
                            <p>
                                Ще не зареєструвались?
                            </p>
                            <a href='/login'>
                                Зареєструватись
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export {RecoveryForm}
