/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styles from './HeaderCatalog.module.css';
import {RequestType} from "./HeaderPage";

const HeaderCatalog: React.FC<{ categories: RequestType[] | undefined }> = ({categories}) => {


    return (
        <div>
            <ul className={styles.catalog__wrapper}>
                <li className={styles.btn__catalog}>
                    <p className={styles.btn__catalog__link} >
                        Каталог товарів
                    </p>
                </li>
                <li className={styles.catalog__list}>
                    <div style={{maxHeight: 'calc(100dvh - 200px)', overflow: 'auto'}}>
                        <ul>
                            {categories && categories.map(({name, subCategories}: RequestType, idx: number) => {
                                return (
                                    <li key={idx} className={styles.catalog__list__item}>
                                        <a className={styles.catalog__list__link}
                                           href={`/catalog/${name.replace(' ', '_')}`}>
                                            {name}
                                            {subCategories.length ? <span className={styles.catalog__list__link_icon}><svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              version='1.1' width='12' height='12'
                                              x='0' y='0'
                                              viewBox='0 0 492.004 492.004'><g><path
                                              d='M382.678 226.804 163.73 7.86C158.666 2.792 151.906 0 144.698 0s-13.968 2.792-19.032 7.86l-16.124 16.12c-10.492 10.504-10.492 27.576 0 38.064L293.398 245.9l-184.06 184.06c-5.064 5.068-7.86 11.824-7.86 19.028 0 7.212 2.796 13.968 7.86 19.04l16.124 16.116c5.068 5.068 11.824 7.86 19.032 7.86s13.968-2.792 19.032-7.86L382.678 265c5.076-5.084 7.864-11.872 7.848-19.088.016-7.244-2.772-14.028-7.848-19.108z'></path></g></svg></span> : ''}
                                        </a>
                                        {subCategories.length ?
                                        <ul className={styles.catalog__list__item__category}>
                                            {subCategories.map((subCategory: string, index: number) => {
                                                return (
                                                    <li key={index}
                                                        className={styles.catalog__list__item__category__item}>
                                                        <a href={`/catalog/${name.replace(' ', '_')}/${subCategory.replace(' ', '_')}`}>
                                                            <p className={styles.category__tile}>
                                                                {subCategory}
                                                            </p>
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                          : '' }
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export {HeaderCatalog}
