import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

import styles from './Product.module.css';
import {ProductType} from "../../../types";
import {useAuthSlice} from "../../../slices/useAuth";
import btnCart from '../../../img/header/btnCart_white.svg';
import ModalWindow from "../ModalWindow/ModalWindow";

const ProductItem = ({data}: { data: ProductType }) => {
    const {addProductToCart, authState: {category, isAuth, cart}} = useAuthSlice()
    const [quantity, setQuatity] = useState<number | string>(0)
    const [isModalOpen, setIsModalOpen] = useState(false);


    const price = data !== undefined && data.prices.find(({crmId}) => crmId && crmId === category)?.value
    const existProduct = cart.find(i => i.id._id === data._id)

    const openModal = () => {
        setIsModalOpen(true);
    };
    useEffect(() => {
        if (quantity < 0) {
            setQuatity(0);
        }
    }, [quantity]);

    useEffect(() => {
        if (existProduct) setQuatity(existProduct.quantity)
        // eslint-disable-next-line
    }, []);
    // useEffect(() => {
    //     // if (data.quantity === 0 && existProduct) removeProductFromCart({id: data._id})
    //     if (data && data.quantity < (cart.find(i => i.id._id === data._id)?.quantity ?? 0)) {
    //         addProductToCart({id: data._id, quantity: Number(data.quantity)});
    //     }
    //     // eslint-disable-next-line
    // }, []);
    return (
        <li className={styles.productItem}>
            <div className={styles.img__wrapper__block}>
                <ul className={styles.tagList}>
                    {data.isDiscountTag && <li style={{background: '#CC3366'}}>Знижка</li>}
                    {data.isReceiptsTag && <li style={{background: `#3faa3d`}}>Надходження</li>}
                    {data.isNewTag && <li style={{background: `#4b7a3d`}}>Новинка</li>}
                </ul>
                <div className={styles.productImg} onClick={openModal}>
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}/${data?.image ? data?.image : 'media/products/woocommerce-placeholder.png'}`}
                        alt={data.name}
                    />
                </div>
                <div className={styles.article__text}>Артикул: {data.article}</div>
            </div>


            <div className={styles.productContent}>
                <div className={styles.productContent__wrapper}>
                    <div className={styles.info__wrapper}>
                        <div className={styles.product__info}>
                            <Link to={`/catalog/product/${data._id}`} className={styles.productTitle}>{data.name}</Link>
                            <div className={styles.productCode}>Артикул: {data.article}</div>
                            {isAuth && <>
                                {/*<div className={styles.productCode}>Залишок товару: {data.quantity}</div>*/}
                                {/*<div className={styles.productCode}>Ціна: {price} грн.</div>*/}
                            </>}
                        </div>
                    </div>
                    {isAuth && <div className={styles.price}>{price} грн.</div>}
                    {isAuth &&
                        <>
                            <div className={styles.counter__block}>
                                <button
                                    className='btn__minus'
                                    disabled={quantity === 0}
                                    onClick={() => setQuatity(p => p && p === 0 ? p : +p - 1)}>-
                                </button>
                                <input
                                    disabled={data?.quantity === 0}
                                    min={0}
                                    // max={data?.quantity}
                                    onFocus={() => setQuatity("")}
                                    onChange={(e) => setQuatity(+e.target.value)}
                                    value={quantity}
                                    type="number"
                                />
                                <button
                                    className='btn__plus'
                                    disabled={data?.quantity === 0}
                                    onClick={() => setQuatity(p => +p + 1)}>
                                    +
                                </button>
                            </div>
                            <button className={styles.order__btn}
                                    disabled={data?.quantity === 0}
                                    onClick={() => {
                                        if (data?._id) {
                                            quantity && quantity > 0 && addProductToCart({
                                                id: data._id,
                                                quantity: +quantity
                                            });
                                        } else {
                                            console.error("Product ID is undefined");
                                        }
                                    }}>
                                <img className={styles.cart__icon} src={btnCart} alt="cart"/>
                                {existProduct ? `В кошику ${existProduct.quantity} шт.` : 'Купити'}
                            </button>
                        </>
                    }
                </div>
            </div>

            {isModalOpen && (
                <ModalWindow
                    onClose={() => setIsModalOpen(false)}
                    image={`${process.env.REACT_APP_SERVER_URL}/${data?.image ? data?.image : 'media/products/woocommerce-placeholder.png'}`}
                />
            )}

        </li>
    )
};

export default ProductItem;
