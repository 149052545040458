import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
// import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import CreateUserPage from "../pages/CreateUserPage/CreateUserPage";
import ListUsersPage from "../pages/ListUsersPage/ListUsersPage";

const PrivateRoutes = () => {
    // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registartion */}
                {/*<Route path='login/*' element={<Navigate to='/admin/dashboard'/>}/>*/}
                <Route path='login/*' element={<Navigate to='/admin/users/create'/>}/>


                {/* Pages */}
                {/*<Route path='/dashboard' element={<DashboardWrapper/>}/>*/}

                {/*<Route path='/products/list' element={<BuilderPageWrapper/>}/>*/}
                {/*<Route path='/products/setting' element={<BuilderPageWrapper/>}/>*/}

                {/*<Route path='/orders/list' element={<BuilderPageWrapper/>}/>*/}
                {/*<Route path='/orders/setting' element={<BuilderPageWrapper/>}/>*/}

                <Route path='/users/create' element={<CreateUserPage/>}/>
                <Route path='/users/list' element={<ListUsersPage/>}/>
                {/*<Route path='/users/list' element={<BuilderPageWrapper/>}/>*/}
                {/*<Route path='/users/setting' element={<BuilderPageWrapper/>}/>*/}

                {/* Lazy Modules */}
                <Route
                    path='/account/*'
                    element={
                        <SuspensedView>
                            <AccountPage/>
                        </SuspensedView>
                    }
                />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
