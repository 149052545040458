import * as Yup from "yup";

const requiredField = (_: string) => "Це поле є обов'язковим";
const phoneRegExp = /^(\+38)?\s*\(?0\d{2}\)?\s*\d{3}[-\s]?\d{2}[-\s]?\d{2}$/;

export const createUserShema = Yup.object().shape({
    username: Yup.string()
        .required(requiredField('username'))
        .matches(/^[A-Za-zА-Яа-яЁёІіЇїЄє\s'-]+$/, "Введіть логін кирилицею або латиницею"),
    lastName: Yup.string()
        .required(requiredField('lastName'))
        .matches(/^[A-Za-zА-Яа-яЁёІіЇїЄє\s'-]+$/, "Введіть прізвище кирилицею або латиницею"),
    firstName: Yup.string()
        .required(requiredField('firstName'))
        .matches(/^[A-Za-zА-Яа-яЁёІіЇїЄє\s'-]+$/, "Введіть ім'я кирилицею або латиницею"),
    email: Yup.string()
        .required(requiredField('email'))
        .matches(/^[a-zA-Z0-9]+([._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/, ' Введіть' +
            ' валідний емейл'),
    password: Yup.string()
        .required(requiredField('password'))
        .matches(/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,32}/g, '6-32 символів,' +
            ' принаймні одна велика, одна мала літера та один спеціальний символ.'),
    category: Yup.string()
        .required(requiredField('category')),
    role: Yup.string()
        .required(requiredField('role'))
        .oneOf(['customer', 'admin'], 'Оберіть роль користувача'),
});

export const updateUserShema = Yup.object().shape({
    lastName: Yup.string()
        .required(requiredField('lastName'))
        .matches(/^[A-Za-zА-Яа-яЁёІіЇїЄє\s'-]+$/, "Введіть прізвище кирилицею або латиницею"),
    firstName: Yup.string()
        .required(requiredField('firstName'))
        .matches(/^[A-Za-zА-Яа-яЁёІіЇїЄє\s'-]+$/, "Введіть ім'я кирилицею або латиницею"),
    email: Yup.string()
        .matches(/^[a-zA-Z0-9]+([._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/, ' Введіть' +
            ' валідний емейл'),
    password: Yup.string()
        .matches(/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,32}/g, '6-32 символів,' +
            ' принаймні одна велика, одна мала літера та один спеціальний символ.'),
    category: Yup.string()
        .required(requiredField('category')),
});
export const loginSchema = Yup.object().shape({
    phone: Yup.string()
      .required(requiredField('Телефон'))
      .matches(phoneRegExp, 'Введіть валідний номер телефону'),
    password: Yup.string()
        .required(requiredField('password'))
});


export const registartionSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(requiredField('Ім\'я')),
    lastName: Yup.string()
      .required(requiredField('Прізвище')),
    phone: Yup.string()
      .required(requiredField('Телефон'))
      .matches(phoneRegExp, 'Введіть валідний номер телефону'),
    settlement: Yup.string()
      .required(requiredField('Населений пункт')),
    email: Yup.string()
      .required(requiredField('Email'))
      .email('Введіть валідний емейл'),
    password: Yup.string()
      .required(requiredField('password')),
    confirmPassword: Yup.string()
      .required(requiredField('Підтвердження паролю'))
      .oneOf([Yup.ref('password')], 'Паролі мають збігатися')
});

export const emailShema = Yup.object().shape({
    email: Yup.string()
        .required(requiredField('email')).matches(/^[a-zA-Z0-9]+([._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/, ' Введіть' +
            ' валідний емейл'),
});
export const verifySchema = Yup.object().shape({
    verifyCode: Yup.string()
        .required(requiredField('verifyCode')),
    password: Yup.string()
        .required(requiredField('password')),
});