import {FC} from 'react';
// import {Link} from "react-router-dom";
import {CreateUserForm} from "../../components/CreateUserForm/CreateUserForm";


const CreateUserPage: FC = () => {
    return (
        <>
            <div className="card card-custom mb-8">
                <div className="card-header">
                    <h3 className="card-title fs-2">Створити користувача</h3>
                    {/*<div className="card-toolbar">*/}
                    {/*    <Link to="/admin/dashboard">*/}
                    {/*        <button type="button" className="btn fs-5 btn-sm btn-light svg-icon">*/}
                    {/*            Відмінити*/}
                    {/*        </button>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                </div>
            </div>
            <CreateUserForm  />
        </>
    );
};

export default CreateUserPage;