
import React from 'react';
import '../../../styles/common.css'
import { HeaderPage } from "../../components/Header/HeaderPage";
import MainSlider from "../../components/MainSlider/MainSlider";
import BrandsSlider from "../../components/BrandsSlider/BrandsSlider";
import { Footer } from "../../components/Footer/Footer";



const HomePage: React.FC = () => {
  return (
    <>
      <HeaderPage />
      <MainSlider />
      <BrandsSlider />
      <Footer />
    </>
  )
}

export { HomePage }
