/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import styles from './Footer.module.css';
import logo from './../../../img/header/default-dark.svg'

const Footer: React.FC = () => {

    return (
        <>
            <a className={styles.btn__up} href="#logo">Вгору</a>

            <footer className={styles.footer__wrapper}>
                <div className='container'>
                    <div className={styles.footer} >
                        <a href="/">
                            <img className={styles.footer__logo} src={logo} alt="log"/>
                        </a>
                        <div className={styles.footer__text}>
                            <p>
                                Всі права захищені. COPYRIGHT © 2023 Інтернет-магазин
                            </p>
                            <p>
                                Створення інтернет магазину
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>

    )
}

export {Footer}
