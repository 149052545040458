import {ProductType} from "../../../types";
import React from "react";
import {Link} from "react-router-dom";
import styles from "./OrderProduct.module.css";

const OrderListProductItem = ({product}: {
    product: {
        _id?: ProductType;
        product: ProductType;
        quantity: number;
        price: number;
        totalPrice: number;
    },

}) => {
    return (
        <li className={styles.details__item}>
            <div className={styles.product__photo}>
                <img
                    src={`${process.env.REACT_APP_SERVER_URL}/${product?.product.image ? product?.product.image : 'media/products/woocommerce-placeholder.png'}`}
                    alt={product?.product.name}/>
            </div>
            <div className={styles.product__info}>
                <Link to={`/catalog/product/${product.product._id}`} className={styles.product__link}>{product?.product.name}</Link>
                <p>{product.price} грн. - {product.quantity} шт. ({product.quantity} шт. х {product.price} грн.
                    = {product.totalPrice} грн.)
                </p>
                <p>Артикул: {product?.product.article}</p>
            </div>
        </li>

    )
};

export default OrderListProductItem;