import React from 'react';
import { HeaderPage } from '../../components/Header/HeaderPage';
import { Footer } from '../../components/Footer/Footer';

export const SuccessBasketPage = () => {

  return (
    <>
      <HeaderPage />
      <div className='container' style={{height: 'calc(100vh - 329px)'}}>
        <h1 style={{textAlign: 'center', paddingTop: 50, fontSize: 30}}>
          Все добре. Замовлення успішно оформлене
        </h1>
      </div>
      <Footer />
    </>
  );
};

