import {Route, Routes, Navigate} from 'react-router-dom'
import {HomePage} from '../pages/public/HomePage'
import {LoginPage} from '../pages/public/LoginPage';
import {RecoveryPage} from '../pages/public/RecoveryPage';
import {ProductPage} from '../pages/public/ProductPage';
import {OrdersPage} from '../pages/public/OrdersPage';
import {BasketPage} from '../pages/public/BasketPage'
import {SuccessBasketPage} from '../pages/public/SuccessBasketPage'
import SearchPage from "../pages/public/SearchPage";


import './../../fonts/FixelVariable/FixelVariable.ttf'
import Catalog from "../pages/public/Catalog";


const PublicRoutes = () => {

    return (

        <Routes>
            <Route>
                <Route path='/' element={<HomePage/>}/>
                <Route path='/login' element={<LoginPage/>}/>
                <Route path='/profile/basket' element={<BasketPage/>}/>
                <Route path='/profile/basket/success' element={<SuccessBasketPage/>}/>
                <Route path='/recovery' element={<RecoveryPage/>}/>
                <Route path='/search' element={<SearchPage/>}/>
                <Route path='/profile/orders' element={<OrdersPage/>}/>
                <Route path='/categories/:tag' element={<Catalog/>}/>
                <Route path='/catalog/:category' element={<Catalog/>}/>
                <Route path='/catalog/:category/:subCategory' element={<Catalog/>}/>
                <Route path='/catalog/:category/:subCategory/:subSubCategory' element={<Catalog/>}/>
                <Route path='/catalog/product/:id' element={<ProductPage/>}/>
                <Route path='*' element={<Navigate to='/error/404'/>}/>
            </Route>
        </Routes>
    )
}

export {PublicRoutes}
