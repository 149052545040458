import React from 'react';
// import {Navigation} from 'swiper/modules';
// import { Swiper, SwiperSlide } from 'swiper/react';



import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import './../MainSlider/swiper.css';

//
// import styles from './BrandsSlider.module.css'
//
// import brand1 from './../../../img/brans_slider/brand1.png';
// import brand2 from './../../../img/brans_slider/brand2.png';
// import brand3 from './../../../img/brans_slider/brand3.png';




const BrandsSlider = () => {

    return (
        <div className="container" >
            {/*<Swiper className={styles.swiper}*/}
            {/*    modules={[Navigation]}*/}
            {/*    loop={true}*/}
            {/*    spaceBetween={10}*/}
            {/*    slidesPerView={1}*/}
            {/*    navigation*/}
            {/*    breakpoints={{*/}

            {/*        380: {*/}
            {/*            slidesPerView: 2,*/}
            {/*            spaceBetween: 10,*/}
            {/*        },*/}
            {/*        768: {*/}
            {/*            slidesPerView: 4,*/}
            {/*            spaceBetween: 10,*/}
            {/*        },*/}
            {/*        1500: {*/}
            {/*            slidesPerView: 6,*/}
            {/*            spaceBetween: 10,*/}
            {/*        },*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <a href="/">*/}
            {/*        <SwiperSlide className={styles.slide}>*/}
            {/*            <img src={brand1} alt="brand"/>*/}
            {/*        </SwiperSlide>*/}
            {/*    </a>*/}
            {/*    <SwiperSlide className={styles.slide}>*/}
            {/*        <a href="/">*/}
            {/*            <img src={brand2} alt="brand"/>*/}
            {/*        </a>*/}
            {/*    </SwiperSlide>*/}
            {/*    <SwiperSlide className={styles.slide}>*/}
            {/*        <a href="/">*/}
            {/*            <img src={brand3} alt="brand"/>*/}
            {/*        </a>*/}
            {/*    </SwiperSlide>*/}
            {/*    <SwiperSlide className={styles.slide}>*/}
            {/*        <a href="/">*/}
            {/*            <img src={brand1} alt="brand"/>*/}
            {/*        </a>*/}
            {/*    </SwiperSlide>*/}
            {/*    <SwiperSlide className={styles.slide}>*/}
            {/*        <a href="/">*/}
            {/*            <img src={brand2} alt="brand"/>*/}
            {/*        </a>*/}
            {/*    </SwiperSlide>*/}
            {/*    <SwiperSlide className={styles.slide}>*/}
            {/*        <a href="/">*/}
            {/*            <img src={brand3} alt="brand"/>*/}
            {/*        </a>*/}
            {/*    </SwiperSlide>*/}
            {/*    <SwiperSlide className={styles.slide}>*/}
            {/*        <a href="/">*/}
            {/*            <img src={brand1} alt="brand"/>*/}
            {/*        </a>*/}
            {/*    </SwiperSlide>*/}
            {/*    <SwiperSlide className={styles.slide}>*/}
            {/*        <a href="/">*/}
            {/*            <img src={brand2} alt="brand"/>*/}
            {/*        </a>*/}
            {/*    </SwiperSlide>*/}
            {/*    <SwiperSlide className={styles.slide}>*/}
            {/*        <a href="/">*/}
            {/*            <img src={brand3} alt="brand"/>*/}
            {/*        </a>*/}
            {/*    </SwiperSlide>*/}
            {/*</Swiper>*/}
        </div>
    );
};

export default BrandsSlider;
