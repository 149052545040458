import styles from './ModalWindow.module.css';
import crossClose from '../../../img/cross-close.png';
import React, {useEffect} from "react";


interface ModalWindowProps {
  onClose: () => void;
  image: string;
}

const ModalWindow: React.FC<ModalWindowProps> = ({ onClose, image }) => {

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (target.classList.contains(styles.backdrop)) {
      onClose();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return(
    <>
      <div className={styles.backdrop} onClick={handleBackdropClick}>Hello</div>
      <div className={styles.zoom__window}>
        <div className={styles.zoom__place}>
          <img src={image} alt="product" />
        </div>
        <button onClick={onClose} className={styles.close__btn}>
          <img  src={crossClose} alt="cart"/>
        </button>
      </div>
    </>
  )
}

export default ModalWindow;