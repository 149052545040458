import React, {useEffect, useState} from "react";
import {HeaderPage} from "../../components/Header/HeaderPage";
import {Footer} from "../../components/Footer/Footer";
import {useAuthSlice} from "../../../slices/useAuth";
import {Link, useNavigate} from "react-router-dom";
import {ProductType} from "../../../types";
import OrderItem from "../../components/OrderItem/OrderItem";
import {axiosApi} from "../../../libs/axios";
import styles from "../../../app/components/OrderListProductItem/OrderProduct.module.css";

export type OrderType = {
    _id: string;
    totalPrice: number;
    products: {
        product: ProductType;
        quantity: number;
        price: number;
        totalPrice: number;
    } [],
    user: string;
    comment: string;
    crmId: string;
    createdOn: string;
    orderNumber: number;
}
export type ShipmentType = {
    _id: string;
    crmId: string;
    createdOn: string;
    order: OrderType;
    products: {
        product: ProductType;
        quantity: number;
        price: number;
        totalPrice: number;
    } []
}
export const OrdersPage = () => {
    const {authState: {isAuth, id}} = useAuthSlice()
    const [orders, setOrders] = useState<OrderType[]>([])
    const [shipments, setShipments] = useState<ShipmentType[]>([])
    const [load, setLoad] = useState<boolean>(false)
    const navigate = useNavigate()
    useEffect(() => {
        if (!isAuth) navigate('/login')
    }, [isAuth, navigate])
    useEffect(() => {
        (async () => {
            try {
                if (id) {
                    setLoad(true)
                    const response = await axiosApi.get(`/api/orders/get-by-user`, {params: {id}});
                    if (response.data.status === 'success') {
                        setOrders(response.data.data)
                        const allShipments = response.data.data.map(({_id}) => axiosApi.get(`/api/shipments/get-by-order`, {params: {id: _id}}))
                        const array = await Promise.all(allShipments)
                        setShipments(array.map(i => i.data && i.data.data ? {...i.data.data} : null).filter(i => i))
                        setLoad(false)
                    }
                }
            } catch (error) {
                setLoad(false)
                console.error(error);
            }
        })();
    }, [id])

    return (
        <>
            <HeaderPage/>
            <div className={styles.order__section}>
                <div className='container'>
                    <div><Link to={'/'} className={styles.order__home__link}>Головна</Link></div>
                    <div className={styles.order__block}>
                        <div className={styles.link__wrapper}>
                            <h1 className={styles.order__link}>Замовлення</h1>
                        </div>
                        {
                            !load ? <>
                                  <div className={styles.order__head}>
                                      <p className={styles.order__cell__number}>№ замовлення</p>
                                      <p className={styles.order__cell__date}>Дата замовлення</p>
                                      <p className={styles.order__cell__sum}>Сума</p>
                                      <p className={styles.order__cell__details}>Деталі замовлення</p>
                                  </div>

                                  <ul className={styles.order__list}>
                                      {orders.length > 0 && orders.map(order => {
                                          const shipment = shipments && shipments.find(({order: o}) => o._id === order._id)
                                          return <OrderItem key={order._id} shipment={shipment ? shipment : null}
                                                            order={order}/>

                                      })}
                                  </ul>
                              </>
                              :

                              <h1>Зачекайте... <span className='spinner-border spinner-border-sm align-large ms-2'></span>
                              </h1>
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

