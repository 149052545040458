import React from 'react';
import { Navigation, Pagination} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';



import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import './swiper.css';


import styles from './MainSlider.module.css'
import slide1 from './../../../img/main_slider/1.png';
import slide2 from './../../../img/main_slider/2.png';
import slide3 from './../../../img/main_slider/3.png';
import slide4 from './../../../img/main_slider/4.png';
import slide5 from './../../../img/main_slider/5.png';


const MainSlider = () => {

    return (
        <div className="container" style={{height:'100vh'}} >
            <Swiper className={styles.swiper}
                modules={[Navigation, Pagination]}
                loop={true}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
            >
                <SwiperSlide>
                    <a href="/catalog/%D0%9A%D0%90%D0%9D%D0%A6%D0%A2%D0%9E%D0%92%D0%90%D0%A0%D0%98" style={{display: "block", textAlign: "center"}}>
                        <img src={slide4} alt="slide"/>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="/catalog/%D0%93%D0%9E%D0%A1%D0%9F%D0%9E%D0%94%D0%90%D0%A0%D0%A1%D0%AC%D0%9A%D0%86_%D0%A2%D0%9E%D0%92%D0%90%D0%A0%D0%98" style={{display: "block", textAlign: "center"}}>
                        <img src={slide2} alt="slide"/>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="/catalog/%D0%A5%D0%86%D0%9C%D0%86%D0%AF" style={{display: "block", textAlign: "center"}}>
                        <img src={slide3} alt="slide"/>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="/catalog/%D0%86%D0%93%D0%A0%D0%90%D0%A8%D0%9A%D0%98" style={{display: "block", textAlign: "center"}}>
                        <img src={slide1} alt="slide"/>
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href="/catalog/%D0%90%D0%9A%D0%A6%D0%86%D0%AF_%D0%9A%D0%92%D0%86%D0%A2%D0%95%D0%9D%D0%AC%20--%20%D0%A2%D0%A0%D0%90%D0%92%D0%95%D0%9D%D0%AC" style={{display: "block", textAlign: "center"}}>
                        <img src={slide5} alt="slide"/>
                    </a>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default MainSlider;
