import React, {Dispatch, SetStateAction, useState} from 'react';
import {Link} from "react-router-dom";
import styles from "./Category.module.css";
import {CategoryInterface} from "./Category";

interface CategoryListProps {
    categories?: CategoryInterface[];
    setPage:Dispatch<SetStateAction<number>>;
}
const CategoryList: React.FC<CategoryListProps> = ({ categories,setPage }) => {
    const [openCategories, setOpenCategories] = useState<{[key: string]: boolean}>({});

    const toggleCategory = (index: string) => {
        setOpenCategories(prev => ({ ...prev, [index]: !prev[index] }));
    };

    return (
        <ul>
            {categories?.map((category, index) => {
                const isOpen = openCategories[`category-${index}`];
                const iconClass = isOpen ? styles.iconOpen : styles.iconClosed;
                const currentPath = window.location.pathname;
                const decodedPath = decodeURIComponent(currentPath);

                // decodedPath === category.link
                return (
                    <li key={`category-${index}`} className={`${styles.categoryItem} ${decodedPath === category.link ? styles.categoryItemActive : ''}`}>
                        <div onClick={()=>setPage(1)} className={styles.categoryHead}>
                            <Link to={category.link}>{category.title}</Link>
                            {category.sub?.length ? (
                                <span className={iconClass} onClick={() => toggleCategory(`category-${index}`)}>
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      viewBox="0 0 492.004 492.004"
                                  >
                                    <path fill="#fff" d="M382.678 226.804 163.73 7.86C158.666 2.792 151.906 0 144.698 0s-13.968 2.792-19.032 7.86l-16.124 16.12c-10.492 10.504-10.492 27.576 0 38.064L293.398 245.9l-184.06 184.06c-5.064 5.068-7.86 11.824-7.86 19.028 0 7.212 2.796 13.968 7.86 19.04l16.124 16.116c5.068 5.068 11.824 7.86 19.032 7.86s13.968-2.792 19.032-7.86L382.678 265c5.076-5.084 7.864-11.872 7.848-19.088.016-7.244-2.772-14.028-7.848-19.108z"></path>
                                  </svg>
                                </span>
                            ) : ''}
                        </div>
                        {category.sub && isOpen && <CategoryList setPage={setPage} categories={category.sub} />}
                    </li>
                );
            })}
        </ul>
    );
};

export default CategoryList;