/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from './HeaderMobile.module.css';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as UserIcon } from '../../../img/header/user_white.svg';
import { RequestType } from './HeaderPage';


type HeaderMobileMenuProps = {
  categories: RequestType[] | undefined;
  isOpen: boolean;
  isAuth: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const HeaderMobileMenu: React.FC<HeaderMobileMenuProps> = ({ categories, isOpen, setIsOpen, isAuth }) => {
  const [currentCategory, setCurrentCategory] = useState<RequestType>({
    name: '',
    subCategories: [],
  });
  const [subMenu, setSubMenu] = useState(false);

  // console.log(categories);

  return (
    <div className={`${styles.mobileMenu} ${isOpen ? styles.open : ''}`}>
      <div className={styles.mobileMenuHead}>
        <Link to={isAuth ? '/profile/orders' : '/login'}>
          <UserIcon />
        </Link>

        <button className={styles.closeMenu} onClick={() => {
          setIsOpen(false);
          setSubMenu(false);
        }}></button>
      </div>

      <div className={`${styles.mobileMenuWrapperList} ${subMenu ? styles.isSub : ''}`}>
        <ul className={styles.mobileMenuList}>
          {categories && categories.map((cat: RequestType, index: number) => {
            const notHaveSubCategories = !cat.subCategories.length;

            return <li key={index} className={notHaveSubCategories ? 'remove--arrow' : ''} onClick={() => {
              if (!notHaveSubCategories) {
                setCurrentCategory(cat);
                setSubMenu(true);
              }
            }}>
              {
                notHaveSubCategories ?
                  <Link to={`/catalog/${cat.name.replace(' ', '_')}`}>{cat.name}</Link>
                  :
                  cat.name
              }
            </li>;
          })}
        </ul>

        <ul className={styles.mobileMenuSubList}>
          <li onClick={() => setSubMenu(false)} className={styles.goBack}>{currentCategory?.name}</li>

          {currentCategory.subCategories.map((item: string, index) =>
            <li key={index} onClick={() => setIsOpen(false)}>
              <Link to={`/catalog/${currentCategory.name.replace(' ', '_')}/${item.replace(' ', '_')}`}>{item}</Link>
            </li>,
          )}
        </ul>
      </div>

    </div>
  );
};

export default HeaderMobileMenu;