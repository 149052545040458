import styles from './ViewedItem.module.css'
import {ProductType} from "../../../types";
import {useNavigate} from "react-router-dom";

const ViewedItem = ({data}: { data: ProductType }) => {
    const navigate = useNavigate()
    return (
        <div onClick={() => navigate(`/catalog/product/${data._id}`)} className={styles.productItem}>
            <div className={styles.productContent}>
                <div className={styles.tagList}>
                    {data.isDiscountTag && <p style={{background: '#CC3366'}}>{data.isDiscountTag}</p>}
                    {data.isReceiptsTag && <p style={{background: `#3faa3d`}}>{data.isReceiptsTag}</p>}
                    {data.isNewTag && <p style={{background: `#4b7a3d`}}>{data.isNewTag}</p>}
                </div>
                <img src={`${process.env.REACT_APP_SERVER_URL}/${data?.image ? data?.image : 'media/products/woocommerce-placeholder.png'}`} alt={data.name}/>
            </div>
            <h2 className={styles.productTitle}>{data.name}</h2>
        </div>

    )
}

export {ViewedItem}
