import React from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ViewedItem } from './ViewedItem';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import styles from '../ViewedProducts/ViewedProducts.module.css'
import {ProductType} from "../../../types";




type ViewedProductsPropsType ={
  products:ProductType[]
}
const ViewedProducts: React.FC<ViewedProductsPropsType> = ({products}) => {

  // const products: Product[] = [
  //   {
  //     title: 'Ручка гелева Робот синя 2 шт (1158)',
  //     imageUrl: 'https://panda-lviv.com.ua/upload/catalog/37300/37294/72c3d7d2-67f2-11ee-8d51-902b3450eddc_beba2bd1-67ff-11ee-8d51-902b3450eddc.jpeg',
  //     tags: [
  //       { value: 'Новинка', color: '#00b8d4' },
  //       { value: 'Знижка', color: '#CC3366' },
  //       { value: 'Надходження', color: '#3faa3d' },
  //     ],
  //   },
  //   {
  //     title: 'Ручка гелева Робот синя 2 шт (1158)',
  //     imageUrl: 'https://panda-lviv.com.ua/upload/catalog/37300/37294/72c3d7d2-67f2-11ee-8d51-902b3450eddc_beba2bd1-67ff-11ee-8d51-902b3450eddc.jpeg',
  //     tags: [
  //       { value: 'Новинка', color: '#00b8d4' },
  //       { value: 'Знижка', color: '#CC3366' },
  //       { value: 'Надходження', color: '#3faa3d' },
  //     ],
  //   },
  //   {
  //     title: 'Ручка гелева Робот синя 2 шт (1158)',
  //     imageUrl: 'https://panda-lviv.com.ua/upload/catalog/37300/37294/72c3d7d2-67f2-11ee-8d51-902b3450eddc_beba2bd1-67ff-11ee-8d51-902b3450eddc.jpeg',
  //     tags: [
  //       { value: 'Новинка', color: '#00b8d4' },
  //       { value: 'Знижка', color: '#CC3366' },
  //       { value: 'Надходження', color: '#3faa3d' },
  //     ],
  //   },
  //   {
  //     title: 'Ручка гелева Робот синя 2 шт (1158)',
  //     imageUrl: 'https://panda-lviv.com.ua/upload/catalog/37300/37294/72c3d7d2-67f2-11ee-8d51-902b3450eddc_beba2bd1-67ff-11ee-8d51-902b3450eddc.jpeg',
  //     tags: [
  //       { value: 'Новинка', color: '#00b8d4' },
  //       { value: 'Знижка', color: '#CC3366' },
  //       { value: 'Надходження', color: '#3faa3d' },
  //     ],
  //   },
  //   {
  //     title: 'Ручка гелева Робот синя 2 шт (1158)',
  //     imageUrl: 'https://panda-lviv.com.ua/upload/catalog/37300/37294/72c3d7d2-67f2-11ee-8d51-902b3450eddc_beba2bd1-67ff-11ee-8d51-902b3450eddc.jpeg',
  //     tags: [
  //       { value: 'Новинка', color: '#00b8d4' },
  //       { value: 'Знижка', color: '#CC3366' },
  //       { value: 'Надходження', color: '#3faa3d' },
  //     ],
  //   },
  //
  // ]

  return (
    <section className={styles.swiper__section}>
      <div className="container" >
        <div className={styles.swiper__wrapper}>
          <h3 className={styles.swiper__title}>Щойно ви переглядали</h3>
          <Swiper className={styles.swiper}
            modules={[Navigation]}
            loop={false}
            slidesPerView={1}
            navigation
            watchOverflow={true}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
          >
            {products.map((item, index) => (
              <SwiperSlide key={index} className={styles.slide}>
                <ViewedItem data={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export { ViewedProducts }



