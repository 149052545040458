import React, {useEffect, useState} from 'react'
import {HeaderPage} from "../../components/Header/HeaderPage";
import {Footer} from "../../components/Footer/Footer";
import {SingleProduct} from '../../components/SingleProduct/SingleProduct';
import {ViewedProducts} from '../../components/ViewedProducts/ViewedProducts';
import {useParams} from "react-router-dom";
import {axiosApi} from "../../../libs/axios";
import {ProductType} from "../../../types";
// import styles from "../../components/Header/HeaderCatalog.module.css";

const ProductPage: React.FC = () => {
    const {id} = useParams()
    const [products, setProducts] = useState<ProductType[]>([])
    useEffect(() => {
        const array = JSON.parse(sessionStorage.getItem('products') || '[]');
        if (array.length > 0) {
            if (array.length < 5) {
                if (!array.includes(id)) {
                    array.push(id)
                }
            } else {
                if (!array.includes(id)) {
                    array.shift()
                    array.push(id)
                }
            }
            sessionStorage.setItem('products', JSON.stringify(array))
        } else {
            sessionStorage.setItem('products', JSON.stringify([id]))
        }
    }, [id]);

    useEffect(() => {
        (async () => {
            try {
                const array = JSON.parse(sessionStorage.getItem('products') || '[]');
                if (array.length) {
                    const res = array.map((id) => axiosApi.get(`/api/products/get-by-id`, {params: {id}}))
                    const responses = await Promise.all(res)
                    setProducts(responses?.map(({data: {data}}: { data: { data: ProductType } }) => ({...data})))
                }
            } catch (e) {

            }
        })()
    }, []);

    return (
        <>
            <HeaderPage/>
            <SingleProduct/>
            <ViewedProducts products={products}/>
            <Footer/>
        </>
    )
}

export {ProductPage}

