/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {PublicRoutes} from './PublicRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {useAuthSlice} from "../../slices/useAuth";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
    const {currentUser} = useAuth()
    const {authMe}=useAuthSlice()
    useEffect(() => {
        authMe()
    }, [authMe]);
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App/>}>
                    <Route path='logout' element={<Logout/>}/>
                    {currentUser ? (
                        <>
                            <Route path='admin/*' element={<PrivateRoutes/>}/>
                            {/*<Route path='admin/' element={<Navigate to='/admin/dashboard/'/>}/>*/}
                            <Route path='admin/' element={<Navigate to='/admin/users/create'/>}/>
                        </>
                    ) : (
                        <>
                            {/*<Route path='auth/*' element={<AuthPage/>}/>*/}
                            <Route path='admin/*' element={<AuthPage/>}/>
                        </>
                    )}
                    <Route path='/error/*' element={<ErrorsPage/>}/>
                 <Route path='/*' element={<PublicRoutes/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}
