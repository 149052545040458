import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import { debounce } from 'lodash';
import {FormikValues, useFormik} from "formik";
import {updateUserShema} from "../../../helpers/schemas";
import clsx from "clsx";
import styles from "../../components/OrderListProductItem/OrderProduct.module.css";
import OrderItem from "../../components/OrderItem/OrderItem";
import {OrderType, ShipmentType} from "../public/OrdersPage";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useSound from 'use-sound';
import boopSfx from '../../sounds/boop.mp3';

type UserListType = {
    firstName: string;
    lastName: string;
    _id: string;
    isNew?: boolean;
}

type formType = {
    firstName: string;
    lastName: string;
    phone?: string;
    category: string;
    email?: string;
    active: boolean;
    city?: string;
    storeName?: string;
    numberOfStores?: string;
    password?: string;
}

const ListUsersPage: FC = () => {
    const [fieldDisabled, setFieldDisabled] = useState(true);
    const [currentUserId, setCurrentUserId] = useState<string>('');
    const [orders, setOrders] = useState<OrderType[]>([])
    const [newUsers, setNewUsers] = useState<UserListType[]>([])
    const [allUsers, setAllUsers] = useState<UserListType[]>([])
    const [shipments, setShipments] = useState<ShipmentType[]>([])
    const [load, setLoad] = useState<boolean>(false)
    const [isGetUser, setIsGetUser] = useState<boolean>(false)
    const [page, setPage] = useState(0);
    const [hasMoreUsers, setHasMoreUsers] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState<UserListType[]>([]);
    const observerTarget = useRef(null);
    const isFirstRun = useRef(true);
    const [play] = useSound(boopSfx);

    const formik = useFormik({
        validationSchema: updateUserShema,
        initialValues: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            city: '',
            storeName: '',
            numberOfStores: '',
            password: '',
            category: '',
            active: null
        },
    onSubmit: async (values, {setSubmitting}) => {
        setSubmitting(true)
        await updateUser(values)
        setSubmitting(false)
    }
    });

    const getAllUsers = async () => {
        if (!hasMoreUsers) return;

        const offset = page * 50;
        try {
            const res = await axios.get(`/api/users/get-all?limit=50&skip=${offset}`);
            if (res.data.status === 'success') {
                if (res.data.data.length > 0) {
                    setAllUsers(prevUsers => {
                        const existingIds = new Set(prevUsers.map(user => user._id));
                        const filteredNewUsers = res.data.data.filter(user => !existingIds.has(user._id));

                        return [...prevUsers, ...filteredNewUsers];
                    });
                    setPage(prevPage => prevPage + 1);
                    setHasMoreUsers(res.data.data.length === 50);
                } else {
                    setHasMoreUsers(false);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getUser = async (id: string) => {
        setIsGetUser(true);
        await axios.get(`/api/users/get-by-id?id=${id}`)
            .then(res => {
                if (res.data.status === 'success') {
                    setCurrentUserId(id);
                    const user = res.data.data;
                    formik.setValues({
                        firstName: user.firstName,
                        lastName: user.lastName,
                        phone: user.phone,
                        category: user.category,
                        email: user.email,
                        active: user.active,
                        city: user.city,
                        storeName: user.storeName,
                        numberOfStores: user.numberOfStores,
                        password: ''
                    });
                }
            })
            .catch(e => {
                console.error(e);
            });

        if (id) {
            setLoad(true)
            const response = await axios.get(`/api/orders/get-by-user`, {params: {id}});
            if (response.data.status === 'success') {
                setOrders(response.data.data)
                const allShipments = response.data.data.map(({_id}) => axios.get(`/api/shipments/get-by-order`, {params: {id: _id}}))
                const array = await Promise.all(allShipments)
                setShipments(array.map(i => i.data && i.data.data ? {...i.data.data} : null).filter(i => i))
                setLoad(false)
            }
        }
    }

    useEffect(() => {
        if (!searchTerm && observerTarget.current) {
            const observer = new IntersectionObserver(
                entries => {
                    if (entries[0].isIntersecting && hasMoreUsers) {
                        setPage((prevPage) => prevPage + 1);
                        getAllUsers().then();
                    }
                },
                { threshold: 0.1 }
            );

            const currentObserverTarget = observerTarget.current;
            if (currentObserverTarget) {
                observer.observe(currentObserverTarget);
            }

            return () => {
                if (currentObserverTarget) {
                    observer.unobserve(currentObserverTarget);
                }
            };
        }
        // eslint-disable-next-line
    }, [hasMoreUsers, allUsers, searchTerm]);

    useEffect(() => {
        getAllUsers().then();
        checkNewUsers().then();

        const intervalId = setInterval(() => {
            checkNewUsers().then(); // Check new users every 3 seconds
        }, 5000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, []);

    const checkNewUsers = async () => {
        try {
            const response = await axios.get(`/api/users/get-new-users`);
            if (response.data.status === "success") {
                const fetchedUsers = response.data.data;
                const existingIds = newUsers.map(user => user._id);
                const uniqueNewUsers = fetchedUsers.filter(user => !existingIds.includes(user._id));

                if (uniqueNewUsers.length > 0) {
                    setNewUsers([...newUsers, ...uniqueNewUsers]);
                    if (!isFirstRun.current) {
                        play();
                    }
                }

                isFirstRun.current = false;
            }
        } catch (error) {
            console.error("Failed to check new users:", error);
        }
    };

    const searchUser = async (value: string) => {
        setSearchTerm(value);
        if (value.trim() === '') {
            setFilteredUsers([]);
            setHasMoreUsers(true);
            return;
        }
        await axios.get(`/api/users/search?s=${value}` ).then((res) => {
            if (res.data.status === "success") setFilteredUsers(res.data.data);
        }).catch((e) => console.error(e));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce(searchUser, 300), []
    );

    const updateUser = async (values: FormikValues) => {
        const formValues: formType = {
                firstName: values.firstName,
                lastName: values.lastName,
                category: values.category,
                active: values.active
        }

        if (values.phone) formValues.phone = values.phone;
        if (values.password) formValues.password = values.password;
        if (values.city) formValues.city = values.city;
        if (values.email) formValues.email = values.email;
        if (values.storeName) formValues.storeName = values.storeName;
        if (values.numberOfStores) formValues.numberOfStores = values.numberOfStores;

        await axios.put(`/api/users/update?id=${currentUserId}`, formValues).then((res) => {
            if (res.data.status === "success") {
                setFieldDisabled(true);
                setSearchTerm('');
                toast.success('Користувач успішно оновлений!', {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).catch((e) => console.error(e));
    }

    useEffect(() => {
        const fetchAndMergeUsers = async () => {
            try {
                const res = await axios.get(`/api/users/get-new-users-by-orders`);
                if (res.data.status === "success") {
                    const newUsers = res.data.data;
                    const taggedNewUsers = newUsers.map(user => ({ ...user, isNew: true }));
                    setAllUsers(prevUsers => {
                        const newIds = new Set(newUsers.map(user => user._id));
                        const filteredOldUsers = prevUsers.filter(user => !newIds.has(user._id));
                        return [...taggedNewUsers, ...filteredOldUsers];
                    });
                }
            } catch (error) {
                console.error("Error fetching new users:", error);
            }
        };

        fetchAndMergeUsers().then();
        const intervalId = setInterval(fetchAndMergeUsers, 30000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ToastContainer/>
            <div className="card card-custom mb-8">
                <div className="card-header">
                    <h3 className="card-title fs-2">Список користувачів</h3>
                    {isGetUser
                        ? <button type="button" className="btn btn-primary my-auto btn-sm"
                                  onClick={() => setFieldDisabled(false)}>Редагувати
                        </button>
                        : null}
                </div>
                <div className="card-body d-flex gap-10">
                    <div className="d-flex flex-grow-1 w-100 flex-column gap-6"
                         style={{minWidth: '300px', maxWidth: '300px'}}>
                        <div className="border border-secondary rounded-1 w-100 overflow-hidden">
                            <div className="text-uppercase fs-5 fw-bold text-center p-2 bg-light-primary border-bottom"
                                 aria-current="true">
                                Нові користувачі
                            </div>
                            <ul className="list-group list-group-flush overflow-auto" style={{maxHeight: '200px'}}>
                                {newUsers.length
                                    ? <>
                                        {newUsers.map((item: {
                                            firstName: string;
                                            lastName: string;
                                            _id: string;
                                        }, index: number) => <li key={index} className="list-group-item">
                                            <button
                                                className="btn btn-link btn-sm p-0"
                                                onClick={() => getUser(item._id)}>{item.firstName} {item.lastName}</button>
                                        </li>)}
                                    </>
                                    : <li className="list-group-item">Нових користувачів немає!</li>
                                }
                            </ul>
                        </div>
                        <div className="border border-secondary rounded-1 w-100 overflow-hidden">
                            <div className="text-uppercase fs-5 fw-bold text-center p-2 bg-light-primary border-bottom"
                                 aria-current="true">
                                Всі користувачі
                            </div>
                            <label className="p-2 w-100 input-group-sm">
                                <input type="search" onChange={(e) => debouncedSearch(e.target.value)}
                                       className="form-control w-100 h-25" placeholder="Пошук"/>
                            </label>
                            <ul className="list-group list-group-flush overflow-auto" style={{maxHeight: '200px'}}>
                                {allUsers.length
                                    ? <>
                                        {(searchTerm.trim() ? filteredUsers : allUsers).map((item: {
                                            firstName: string;
                                            lastName: string;
                                            _id: string;
                                            isNew?: boolean;
                                        }, index: number) => <li key={index} className={`list-group-item ${item.isNew ? 'bg-secondary' : ''}`}>
                                            <button
                                                className="btn btn-link btn-sm p-0"
                                                onClick={() => {
                                                    setAllUsers(prevUsers =>
                                                        prevUsers.map(u =>
                                                            u._id === item._id ? { ...u, isNew: false } : u
                                                        )
                                                    );
                                                    return getUser(item._id);
                                                }}>{item.firstName} {item.lastName}</button>
                                        </li>)}
                                        {!searchTerm && hasMoreUsers ?
                                            <li style={{height: '1px', border: 'none', color: 'transparent'}}
                                                ref={observerTarget}>load</li> : null}
                                    </>
                                    : <li className="list-group-item">Користувачів немає!</li>
                                }
                            </ul>
                        </div>
                    </div>

                    {isGetUser
                        ? <form onSubmit={formik.handleSubmit} className='form w-100'>
                            <div className="d-flex gap-5">
                                <div className='fv-row mb-4 w-100'>
                                    <label
                                        className='form-label fs-6 fw-bolder text-dark'>
                                        Ім'я
                                    </label>
                                    <input
                                        className={clsx(
                                            'form-control bg-transparent',
                                            {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                                            {
                                                'is-valid': formik.touched.firstName && !formik.errors.firstName,
                                            }
                                        )}
                                        disabled={fieldDisabled}
                                        type="text"
                                        name="firstName"
                                        placeholder="Ім'я"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.firstName}
                                        autoComplete='off'
                                    />
                                    {formik.touched.firstName && formik.errors.firstName && (
                                        <div className='fv-plugins-message-container'>
                                                    <span role='alert'
                                                          style={{color: '#f1416c'}}>{formik.errors.firstName}</span>
                                        </div>
                                    )}
                                </div>
                                <div className='fv-row mb-4 w-100'>
                                    <label
                                        className='form-label fs-6 fw-bolder text-dark'>
                                        Прізвище
                                    </label>
                                    <input
                                        className={clsx(
                                            'form-control bg-transparent',
                                            {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                                            {
                                                'is-valid': formik.touched.lastName && !formik.errors.lastName,
                                            }
                                        )}
                                        disabled={fieldDisabled}
                                        type="text"
                                        name="lastName"
                                        placeholder="Прізвище"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.lastName}
                                        autoComplete='off'
                                    />
                                    {formik.touched.lastName && formik.errors.lastName && (
                                        <div className='fv-plugins-message-container'>
                                                    <span role='alert'
                                                          style={{color: '#f1416c'}}>{formik.errors.lastName}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex gap-5">
                                <div className='fv-row mb-4 w-100'>
                                    <label
                                        className='form-label fs-6 fw-bolder text-dark'>
                                        Номер телефону
                                    </label>
                                    <input
                                        type="text"
                                        placeholder='Телефон'
                                        name="phone"
                                        disabled={fieldDisabled}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phone}
                                        autoComplete='off'
                                        className={clsx(
                                            'form-control bg-transparent',
                                            {'is-invalid': formik.touched.phone && formik.errors.phone},
                                            {
                                                'is-valid': formik.touched.phone && !formik.errors.phone,
                                            }
                                        )}
                                    />
                                    {formik.touched.phone && formik.errors.phone && (
                                        <div className='fv-plugins-message-container'>
                                                    <span role='alert'
                                                          style={{color: '#f1416c'}}>{formik.errors.phone}</span>
                                        </div>
                                    )}
                                </div>
                                <div className='fv-row mb-4 w-100'>
                                    <label className='form-label fs-6 fw-bolder text-dark'>Населений
                                        пункт</label>
                                    <input
                                        type="text"
                                        placeholder='Населений пункт'
                                        name="city"
                                        disabled={fieldDisabled}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.city}
                                        autoComplete='off'
                                        className={clsx(
                                            'form-control bg-transparent',
                                            {'is-invalid': formik.touched.city && formik.errors.city},
                                            {
                                                'is-valid': formik.touched.city && !formik.errors.city,
                                            }
                                        )}
                                    />
                                    {formik.touched.city && formik.errors.city && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert' style={{color: '#f1416c'}}>{formik.errors.city}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex gap-5">
                                <div className='fv-row mb-4 w-100'>
                                    <label className='form-label fs-6 fw-bolder text-dark'>Назва
                                        магазину</label>
                                    <input
                                        type="text"
                                        placeholder='Назва магазину'
                                        name="storeName"
                                        disabled={fieldDisabled}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.storeName}
                                        autoComplete='off'
                                        className={clsx(
                                            'form-control bg-transparent',
                                            {'is-invalid': formik.touched.storeName && formik.errors.storeName},
                                            {
                                                'is-valid': formik.touched.storeName && !formik.errors.storeName,
                                            }
                                        )}
                                    />
                                    {formik.touched.storeName && formik.errors.storeName && (
                                        <div className='fv-plugins-message-container'>
                                                    <span role='alert'
                                                          style={{color: '#f1416c'}}>{formik.errors.storeName}</span>
                                        </div>
                                    )}
                                </div>
                                <div className='fv-row mb-4 w-100'>
                                    <label className='form-label fs-6 fw-bolder text-dark'>Кількість
                                        магазинів</label>
                                    <input
                                        type="text"
                                        placeholder='Кількість магазинів'
                                        name="numberOfStores"
                                        disabled={fieldDisabled}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.numberOfStores}
                                        autoComplete='off'
                                        className={clsx(
                                            'form-control bg-transparent',
                                            {'is-invalid': formik.touched.numberOfStores && formik.errors.numberOfStores},
                                            {
                                                'is-valid': formik.touched.numberOfStores && !formik.errors.numberOfStores,
                                            }
                                        )}
                                    />
                                    {formik.touched.numberOfStores && formik.errors.numberOfStores && (
                                        <div className='fv-plugins-message-container'>
                                                    <span role='alert'
                                                          style={{color: '#f1416c'}}>{formik.errors.numberOfStores}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex gap-5 align-items-center">
                                <div className='fv-row mb-4 w-100'>
                                    <label htmlFor='email'
                                           className='form-label fs-6 fw-bolder text-dark'>Email</label>
                                    <input
                                        id='email'
                                        type="email"
                                        placeholder='Email'
                                        name="email"
                                        disabled={fieldDisabled}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        autoComplete='off'
                                        className={clsx(
                                            'form-control bg-transparent',
                                            {'is-invalid': formik.touched.email && formik.errors.email},
                                            {
                                                'is-valid': formik.touched.email && !formik.errors.email,
                                            }
                                        )}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert' style={{color: '#f1416c'}}>{formik.errors.email}</span>
                                        </div>
                                    )}
                                </div>
                                <div className='fv-row mb-4 w-100'>
                                    <label
                                        className='form-label fs-6 fw-bolder text-dark'>Пароль</label>
                                    <input
                                        type="password"
                                        placeholder='Пароль'
                                        name="password"
                                        disabled={fieldDisabled}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                        autoComplete="false"
                                        className={clsx(
                                            'form-control bg-transparent',
                                            {'is-invalid': formik.touched.password && formik.errors.password},
                                            {
                                                'is-valid': formik.touched.password && !formik.errors.password,
                                            }
                                        )}
                                    />
                                    {formik.touched.password && formik.errors.password && (
                                        <div className='fv-plugins-message-container'>
                                                    <span role='alert'
                                                          style={{color: '#f1416c'}}>{formik.errors.password}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex gap-5 align-items-center">
                                <div className='fv-row mb-6 w-100'>
                                    <label className='form-label fs-6 fw-bolder text-dark'>Тип ціни</label>
                                    <select
                                        name="category"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={fieldDisabled}
                                        value={formik.values.category}
                                        style={{cursor: 'pointer'}}

                                        className={clsx('form-control bg-transparent',
                                            {'is-invalid': formik.touched.category && formik.errors.category},
                                            {'is-valid': formik.touched.category && !formik.errors.category}
                                        )}
                                    >
                                        <option value=''>Оберіть категорію</option>
                                        <option value='f6842ab0-b70b-11eb-bb21-180373b9e379'>Закупка</option>
                                        <option value='f6842ab1-b70b-11eb-bb21-180373b9e379'>Опт А</option>
                                        <option value='f68e47bd-301a-11ec-bbff-180373b9e379'>Опт Б</option>
                                        <option value='f6842ab2-b70b-11eb-bb21-180373b9e379'>Роздріб</option>
                                        <option value='3bc871fc-ca46-11ed-be0b-180373b9e379'>А+</option>
                                        <option value='c7e079ff-cc45-11eb-bb4a-180373b9e379'>Опт В</option>
                                    </select>
                                    {formik.touched.category && formik.errors.category && (
                                        <div className='fv-plugins-message-container'>
                                                    <span role='alert'
                                                          style={{color: '#f1416c'}}>{formik.errors.category}</span>
                                        </div>
                                    )}
                                </div>
                                <div className='fv-row d-flex gap-5 mb-12 w-100 mt-auto'>
                                    <div className="form-check">
                                        <input className="form-check-input" disabled={fieldDisabled}
                                               type="radio" name="active"
                                               value="true"  // Formik handles this as a string
                                               checked={formik.values.active === true}  // Compare with true explicitly
                                               onChange={() => formik.setFieldValue("active", true)}
                                               id="flexRadioDefault1"/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Активований
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" disabled={fieldDisabled}
                                               type="radio" name="active"
                                               value="false"  // Formik handles this as a string
                                               checked={formik.values.active === false}  // Compare with true explicitly
                                               onChange={() => formik.setFieldValue("active", false)}
                                               id="flexRadioDefault2"/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            Не активований
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {!fieldDisabled
                                ? <div className='d-grid mb-10 w-25'>
                                    <button
                                        type='submit'
                                        className='btn btn-primary fw-bold px-6'
                                        disabled={formik.isSubmitting || fieldDisabled}
                                    >
                                        {!formik.isSubmitting ? <span className='indicator-label'>Оновити</span>
                                            : (
                                                <span className='indicator-progress' style={{display: 'block'}}>
                              Будь ласка зачекайте...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                                            )}
                                    </button>
                                </div>
                                : null
                            }

                        </form>
                        : <h2 className="fs-3 fw-bold">Виберіть користувача!</h2>
                    }

                </div>
            </div>

            <div className={styles.order__section}>
                <div className='container'>
                    <div className={styles.order__block}>
                        <div className={styles.link__wrapper}>
                            <h1 className={styles.order__link}>Історія замовлень користувача</h1>
                        </div>
                        {
                            !load ? <>
                                    <div className={styles.order__head}>
                                        <p className={styles.order__cell__number}>№ замовлення</p>
                                        <p className={styles.order__cell__date}>Дата замовлення</p>
                                        <p className={styles.order__cell__sum}>Сума</p>
                                        <p className={styles.order__cell__details}>Деталі замовлення</p>
                                    </div>

                                    <ul className={styles.order__list}>
                                        {orders.length > 0 && orders.map(order => {
                                            const shipment = shipments && shipments.find(({order: o}) => o._id === order._id)
                                            return <OrderItem key={order._id} shipment={shipment ? shipment : null}
                                                              order={order}/>

                                        })}
                                    </ul>
                                </>
                                :

                                <h1>Зачекайте... <span
                                    className='spinner-border spinner-border-sm align-large ms-2'></span>
                                </h1>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListUsersPage;