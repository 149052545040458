/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import styles from './HeaderMobile.module.css';


import logo from '../../../img/header/default-dark.svg';
// import search from '../../../img/header/btn-search__white.svg';
import cart from '../../../img/header/btnCart_white.svg';
import HeaderMobileMenu from "./HeaderMobileMenu";
import {RequestType} from "./HeaderPage";
import { Link } from 'react-router-dom';

const HeaderMobile: React.FC<{ categories: RequestType[] | undefined, isAuth: boolean }> = ({categories, isAuth}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    return (
        <>
            <div className={styles.header__mobile}>
                <div className={styles.container__mobile}>
                    <div className={styles.header__mobile__wrapper}>
                        <div className={styles.header__mobile_left}>
                            <div className={styles.btn__mobile_menu} onClick={() => setIsOpen(true)}>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                          <Link to='/'>
                            <img className={styles.header__mobile__logo} src={logo} alt='logo' />
                          </Link>
                        </div>
                      <ul className={styles.header__mobile_right}>
                            {/*<li>*/}
                            {/*    <a className={styles.header__mobile_right__link} href="/#">*/}
                            {/*        <img className={styles.mobile__link__svg} src={search} alt="search"/>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            {isAuth ?
                              <li>
                                  <Link className={styles.header__mobile_right__link} to="/profile/basket">
                                      <img className={styles.mobile__link__svg} src={cart} alt="search" />
                                  </Link>
                              </li>
                              : ''}
                        </ul>
                    </div>
                </div>
            </div>
            <HeaderMobileMenu isOpen={isOpen} setIsOpen={setIsOpen} categories={categories} isAuth={isAuth} />
        </>
    );
};

export default HeaderMobile;