import React, { useEffect, useState } from 'react';

import styles from '../LoginForm/LoginForm.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthSlice } from '../../../slices/useAuth';
import { Formik } from 'formik';
import { loginSchema, registartionSchema } from '../../../helpers/schemas';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import axios from 'axios';
import { toast } from 'react-toastify';
import { InputMask } from 'primereact/inputmask';

const LoginForm: React.FC = () => {
  const isActive = true;
  const { login, authState } = useAuthSlice();
  const [show, setShow] = useState(false);
  const [showReg, setShowReg] = useState(false);
  const [showRegConf, setShowRegConf] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (authState.isAuth) navigate('/');
  }, [authState.isAuth, navigate]);


  return (
    <section className={styles.form__section}>
      <div className='container'>
        <div className={styles.link__block}>
          <a href='/' className={isActive ? styles.inactiveLink : styles.activeLink}>
            Головна
          </a>
          <b>/</b>
          <a href='/login' className={isActive ? styles.activeLink : styles.inactiveLink}>
            Увійти
          </a>
        </div>
        <div className={styles.login__block}>
          <div className={styles.login__block__form}>
            <h3 className={styles.login__block__title}>
              Увійти
            </h3>
            <div className={styles.login__form}>
              <Formik
                validationSchema={loginSchema}
                initialValues={{

                  phone: '',
                  password: '',

                }}
                onSubmit={async (values) => {
                  login({...values, phone: values.phone.replace(/[^0-9]/g, '')});
                }}
              >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                  <form onSubmit={handleSubmit} className='form w-100'>
                    <div className='fv-row mb-4'>
                      {/*<label htmlFor='email'*/}
                      {/*       className='form-label fs-6 fw-bolder text-dark'>Email</label>*/}
                      <InputMask
                        mask="+38 (099) 999-9999"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={clsx(
                          'form-control bg-transparent',
                          { 'is-invalid': touched.phone && errors.phone },
                          { 'is-valid': touched.phone && !errors.phone },
                        )}
                        placeholder="Телефон"
                        type="tel"
                        name="phone"
                        id="phone"
                        autoComplete="off"
                      />
                      {touched.phone && errors.phone && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' style={{ color: '#f1416c' }}>{errors.phone}</span>
                        </div>
                      )}
                    </div>
                    <div className='fv-row mb-4'>
                      {/*<label htmlFor='password'*/}
                      {/*       className='form-label fs-6 fw-bolder text-dark'>Пароль</label>*/}
                      <div style={{ position: 'relative' }}>
                        <input
                          id='password'
                          type={`${show ? 'text' : 'password'}`}
                          placeholder='Пароль'
                          name='password'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          autoComplete='off'
                          style={{ position: 'relative' }}
                          className={clsx(
                            'form-control bg-transparent',
                            { 'is-invalid': touched.password && errors.password },
                            {
                              'is-valid': touched.password && !errors.password,
                            },
                          )}
                        />
                        <span onClick={() => setShow(!show)} className={'password-svg'}>
                            {show ? <SVG src={toAbsoluteUrl('/media/svg/password/eye-open.svg')} /> :
                              <SVG src={toAbsoluteUrl('/media/svg/password/eye-close.svg')} />}
                        </span>
                      </div>
                      {touched.password && errors.password && (
                        <div className='fv-plugins-message-container'>
                                                    <span role='alert'
                                                          style={{ color: '#f1416c' }}>{errors.password}</span>
                        </div>
                      )}
                    </div>


                    <div className='d-grid mb-10'>
                      <button
                        type='submit'
                        className='btn btn-primary fw-bold px-6'
                        disabled={authState.isLoading}
                      >
                        {!authState.isLoading ? <span className='indicator-label'>Увійти</span>
                          : (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Будь ласка зачекайте...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
              <div className={styles.login__form__item}>
                <Link to='/recovery' className={styles.login__form__link}>
                  Забули пароль?
                </Link>
              </div>
            </div>
          </div>

          <div className={styles.login__block__registration}>
            <h3 className={styles.login__block__title}>
              Реєстрація
            </h3>
            <div className={styles.login__form}>
              <Formik
                validationSchema={registartionSchema}
                initialValues={{
                  firstName: '',
                  lastName: '',
                  phone: '',
                  settlement: '',
                  storeName: '',
                  numberOfStores: '',
                  email: '',
                  confirmPassword: '',
                  password: '',
                }}
                onSubmit={async (values, { resetForm }) => {
                  setIsRegistering(true); // Встановити лоадер активним
                  try {
                    await axios.post('/api/users/registration', { ...values, phone: values.phone.replace(/[^0-9]/g, '') });
                    toast.success('Реєстрація пройшла успішно, очікуйте на активацію від адміністратора сайту');
                    resetForm();
                  } catch (e) {
                    console.error(e);
                    toast.error('Помилка реєстрації! Даний email або телефон вже зайнято!');
                  } finally {
                    setIsRegistering(false); // Вимкнути лоадер після завершення запиту
                  }
                }}
              >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                  <form onSubmit={handleSubmit} className='form w-100'>
                    <div className='fv-row mb-4'>
                      {/*<label htmlFor='firstName'*/}
                      {/*       className='form-label fs-6 fw-bolder text-dark'>Ім'я</label>*/}
                      <input
                        id='firstName'
                        type='text'
                        placeholder="Ім'я"
                        name='firstName'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        autoComplete='off'
                        className={clsx(
                          'form-control bg-transparent',
                          { 'is-invalid': touched.firstName && errors.firstName },
                          {
                            'is-valid': touched.firstName && !errors.firstName,
                          },
                        )}
                      />
                      {touched.firstName && errors.firstName && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' style={{ color: '#f1416c' }}>{errors.firstName}</span>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-4'>
                      {/*<label htmlFor='lastName'*/}
                      {/*       className='form-label fs-6 fw-bolder text-dark'>Прізвище</label>*/}
                      <input
                        id='lastName'
                        type='text'
                        placeholder='Прізвище'
                        name='lastName'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        autoComplete='off'
                        className={clsx(
                          'form-control bg-transparent',
                          { 'is-invalid': touched.lastName && errors.lastName },
                          {
                            'is-valid': touched.lastName && !errors.lastName,
                          },
                        )}
                      />
                      {touched.lastName && errors.lastName && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' style={{ color: '#f1416c' }}>{errors.lastName}</span>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-4'>
                      {/*<label htmlFor='phone'*/}
                      {/*       className='form-label fs-6 fw-bolder text-dark'>Телефон</label>*/}
                      <InputMask
                        mask="+38 (099) 999-9999"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={clsx(
                          'form-control bg-transparent',
                          { 'is-invalid': touched.phone && errors.phone },
                          { 'is-valid': touched.phone && !errors.phone },
                        )}
                        placeholder="Телефон"
                        type="tel"
                        name="phone"
                        id="phone"
                        autoComplete="off"
                      />
                      {touched.phone && errors.phone && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' style={{ color: '#f1416c' }}>{errors.phone}</span>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-4'>
                      {/*<label htmlFor='settlement'*/}
                      {/*       className='form-label fs-6 fw-bolder text-dark'>Населений пункт</label>*/}
                      <input
                        id='settlement'
                        type='text'
                        placeholder='Населений пункт'
                        name='settlement'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.settlement}
                        autoComplete='off'
                        className={clsx(
                          'form-control bg-transparent',
                          { 'is-invalid': touched.settlement && errors.settlement },
                          {
                            'is-valid': touched.settlement && !errors.settlement,
                          },
                        )}
                      />
                      {touched.settlement && errors.settlement && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' style={{ color: '#f1416c' }}>{errors.settlement}</span>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-4'>
                      {/*<label htmlFor='storeName'*/}
                      {/*       className='form-label fs-6 fw-bolder text-dark'>Назва магазину</label>*/}
                      <input
                        id='storeName'
                        type='text'
                        placeholder='Назва магазину'
                        name='storeName'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.storeName}
                        autoComplete='off'
                        className={clsx(
                          'form-control bg-transparent',
                          { 'is-invalid': touched.storeName && errors.storeName },
                          {
                            'is-valid': touched.storeName && !errors.storeName,
                          },
                        )}
                      />
                      {touched.storeName && errors.storeName && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' style={{ color: '#f1416c' }}>{errors.storeName}</span>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-4'>
                      {/*<label htmlFor='numberOfStores'*/}
                      {/*       className='form-label fs-6 fw-bolder text-dark'>Кількість магазинів</label>*/}
                      <input
                        id='numberOfStores'
                        type='number'
                        placeholder='Кількість магазинів'
                        name='numberOfStores'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.numberOfStores}
                        autoComplete='off'
                        className={clsx(
                          'form-control bg-transparent',
                          { 'is-invalid': touched.numberOfStores && errors.numberOfStores },
                          {
                            'is-valid': touched.numberOfStores && !errors.numberOfStores,
                          },
                        )}
                      />
                      {touched.numberOfStores && errors.numberOfStores && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' style={{ color: '#f1416c' }}>{errors.numberOfStores}</span>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-4'>
                      {/*<label htmlFor='registerEmail'*/}
                      {/*       className='form-label fs-6 fw-bolder text-dark'>Email</label>*/}
                      <input
                        id='registerEmail'
                        type='email'
                        placeholder='Email'
                        name='email'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        autoComplete='off'
                        className={clsx(
                          'form-control bg-transparent',
                          { 'is-invalid': touched.email && errors.email },
                          {
                            'is-valid': touched.email && !errors.email,
                          },
                        )}
                      />
                      {touched.email && errors.email && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' style={{ color: '#f1416c' }}>{errors.email}</span>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-4'>
                      {/*<label htmlFor='registerPassword'*/}
                      {/*       className='form-label fs-6 fw-bolder text-dark'>Пароль</label>*/}
                      <div style={{ position: 'relative' }}>
                        <input
                          id='registerPassword'
                          type={`${showReg ? 'text' : 'password'}`}
                          placeholder='Пароль'
                          name='password'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          autoComplete='off'
                          style={{ position: 'relative' }}
                          className={clsx(
                            'form-control bg-transparent',
                            { 'is-invalid': touched.password && errors.password },
                            {
                              'is-valid': touched.password && !errors.password,
                            },
                          )}
                        />
                        <span onClick={() => setShowReg(!showReg)} className={'password-svg'}>
                            {showReg ? <SVG src={toAbsoluteUrl('/media/svg/password/eye-open.svg')} /> :
                              <SVG src={toAbsoluteUrl('/media/svg/password/eye-close.svg')} />}
                        </span>
                      </div>
                      {touched.password && errors.password && (
                        <div className='fv-plugins-message-container'>
                                                    <span role='alert'
                                                          style={{ color: '#f1416c' }}>{errors.password}</span>
                        </div>
                      )}
                    </div>

                    <div className='fv-row mb-4'>
                      {/*<label htmlFor='confirmPassword'*/}
                      {/*       className='form-label fs-6 fw-bolder text-dark hidden'>Підтвердження паролю</label>*/}
                      <div style={{ position: 'relative' }}>
                        <input
                          id='confirmPassword'
                          type={`${showRegConf ? 'text' : 'password'}`}
                          placeholder='Підтвердження паролю'
                          name='confirmPassword'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          autoComplete='off'
                          style={{ position: 'relative' }}
                          className={clsx(
                            'form-control bg-transparent',
                            { 'is-invalid': touched.confirmPassword && errors.confirmPassword },
                            {
                              'is-valid': touched.confirmPassword && !errors.confirmPassword,
                            },
                          )}
                        />
                        <span onClick={() => setShowRegConf(!showRegConf)} className={'password-svg'}>
                            {showRegConf ? <SVG src={toAbsoluteUrl('/media/svg/password/eye-open.svg')} /> :
                              <SVG src={toAbsoluteUrl('/media/svg/password/eye-close.svg')} />}
                        </span>
                      </div>
                      {touched.confirmPassword && errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                                                    <span role='alert'
                                                          style={{ color: '#f1416c' }}>{errors.confirmPassword}</span>
                        </div>
                      )}
                    </div>

                    <div className='d-grid mb-10'>
                      <button
                        type='submit'
                        className='btn btn-primary fw-bold px-6'
                        disabled={isRegistering || authState.isLoading} // Додайте перевірку стану isRegistering
                      >
                        {isRegistering ? (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Зачекайте...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : <span className='indicator-label'>Зареєструватися</span>}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export { LoginForm };
